import React from 'react'
import PropTypes from 'prop-types'

import { StyledFlightSearchNavigation, StyledNavigationList } from './styled';

const host = process.env.LOCALE_ORIGIN;

function FormNavigation({ 
  oneWayTitle, 
  returnTitle, 
  multiLegTitle, 
  flightSearchPageLink, 
  isWidget,
  switchFlightSearchForm
}) {
  return (
    <StyledFlightSearchNavigation>
      <StyledNavigationList isWidget={isWidget}>
        <li><a onClick={() => switchFlightSearchForm("one-way")}>{oneWayTitle}</a></li>
        <li><a onClick={() => switchFlightSearchForm("return")}>{returnTitle}</a></li>
        <li><a href={`${host}${flightSearchPageLink}?flightSearchType=multi-leg`}>{multiLegTitle}</a></li>
      </StyledNavigationList>
    </StyledFlightSearchNavigation>
  )
}

FormNavigation.propTypes = {
  flightSearchPageLink: PropTypes.string,
  isWidget: PropTypes.bool,
  multiLegTitle: PropTypes.string, 
  oneWayTitle: PropTypes.string, 
  returnTitle: PropTypes.string, 
  switchFlightSearchForm: PropTypes.func, 
}

export default FormNavigation

