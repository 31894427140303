/**
 * This function checks for airfield domestic flight flag and returns false or true to allow flight search submission or not.
 * @author Andrew Bedziak
 * 
 * @param {arriveAirport} string 
 * @param {departAirport} string 
 * @param {departDomesticFlight} object
 * @param {arriveDomesticFlight} object

 * @returns {boolean} 
 */

export default function checkIfDomesticFlightIsAllowed(
  arriveAirport, 
  departAirport, 
  departDomesticFlight, 
  arriveDomesticFlight
) {
  if (
    departAirport && 
    arriveAirport && 
    departDomesticFlight && 
    arriveDomesticFlight
  ) {
    if (
      departDomesticFlight.flag === 1 && 
      arriveDomesticFlight.flag === 1 && 
      departDomesticFlight.countryName === arriveDomesticFlight.countryName
    ) {
      return false
    } else {
      return true
    }
  } else {
    return null
  }
}