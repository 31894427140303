import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon';

import { StyledDatePicker, StyledDatePickerInput } from './styled';

import CalendarIcon from '../svgs/calendarIcon'

import 'react-day-picker/lib/style.css';
class DatePickerSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDay: null,
      showCalendar: false,
      dateInputValue: ""
    };

    this.windowGlobal = typeof window !== 'undefined' && window;
  }

  componentDidMount() {
    if (this.windowGlobal) {
      this.windowGlobal.addEventListener('mousedown', this.handleClickOutside);
      this.windowGlobal.addEventListener('popstate', this.onBackButtonEvent);  
    }
  }

  componentWillUnmount() {
    if (this.windowGlobal) {
      this.windowGlobal.removeEventListener('mousedown', this.handleClickOutside);
      this.windowGlobal.removeEventListener('popstate', this.onBackButtonEvent);  
    }

    this.setState({dateInputValue: null})
  }

  onBackButtonEvent = () => {
    this.setState({
      selectedDay: null,
      showCalendar: false,
      dateInputValue: ""
    })
  }

  handleCalendarDisplay = (e) => {
    this.setState({
      showCalendar: true
    })
  }

  handleDayClick = (day, modifiers = {} ) => {
    if (modifiers.disabled) {
      return;
    }

    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
      showCalendar: false,
      dateInputValue: DateTime.fromISO(day.toISOString()).toFormat('dd/LL/yyyy')
    }, () => {
      this.props.validateDate(day.toISOString().slice(0, day.toISOString().indexOf("T")))

      sessionStorage.setItem("departureDate", DateTime.fromISO(day.toISOString()).toFormat('yyyy-LL-dd'))
    });
  }

  handleClickOutside = (e) => {
    const calendar = document.querySelectorAll(".calendar-flight-search")[0];

    if (
      e.target !== calendar && 
      calendar && !calendar.contains(e.target) 
      ) {
        this.setState({
          showCalendar: false
        })
    }
  }

  render() {
    const { disabledDate, isMobile, defaultDate, flightSearchIcon, datePlaceholder, formType } = this.props;

    return (
      <React.Fragment>
        <div style={{ width: '47.5%', position : 'relative' }}>
          <CalendarIcon />
          <StyledDatePickerInput 
            value={this.state.dateInputValue}
            placeholder={datePlaceholder}
            readonly={true}
            onFocus={this.handleCalendarDisplay}
            mobile={isMobile}
            flightSearchIcon={flightSearchIcon}
            type="text"
            readOnly={true}
            autoComplete="off"
          />
        
        </div>
        {
          this.state.showCalendar ?
            <StyledDatePicker 
              className="calendar-flight-search"
              id='date-picker-calendar'
              selectedDay={this.state.selectedDay}
              onDayClick={this.handleDayClick}
              formType={formType}
              disabledDays={[
                { before: new Date(disabledDate) }
              ]}
            />            
        : null }
      </React.Fragment>
    )
  }
}

DatePickerSelect.propTypes = {
  datePlaceholder: PropTypes.string,
  defaultDate: PropTypes.string,
  disabledDate: PropTypes.string,
  flightSearchIcon: PropTypes.object,
  formType: PropTypes.string,
  isMobile: PropTypes.bool,
  validateDate: PropTypes.func,
}

export default DatePickerSelect

