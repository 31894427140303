/**
 * This function loads airfields data on hover event only in desktop mode.
 * @author Andrew Bedziak
 * 
 * @param {isManifestLoaded} boolean 
 * @param {preview} boolean 
 * @param {isMobile} boolean 
 * @param {self}  

 * @returns {undefined} 
 */

const airfieldsURL = process.env.GATSBY_AIRFIELDS_URL;

export default function loadAirfieldDataOnHover(isManifestLoaded, preview, isMobile, self) {
  if (isManifestLoaded === false) {
    if (!preview && !isMobile) {
      fetch(airfieldsURL)
        .then(res => res.json())
        .then(data => {
          self.setState({ 
            airfieldsJSON: data.airfields, 
            isManifestLoaded: true 
          })
        })
        .catch(err => console.log(err))
    }
  }   
}