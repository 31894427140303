/* eslint react/no-unused-state: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isMobile } from 'react-device-detect';

import ButtonWrapper from './ButtonWrapper';
import ErrorMessage from './Errors/ErrorMessage';
import MainForm from './MainForm';
import PassengersCount from './PassengerInput';
import DateAndTimeForm from './DateAndTimeForm';
import FormNavigation from './FormNavigation';

import { StyledForm } from './styled';

import './styles.css'

import createProperties from 'utils/functions/createProperties';
import checkIfDomesticFlightIsAllowed from 'components/flightSearch/component/functions/checkIfDomesticFlightIsAllowed';
import cleatInputFields from 'components/flightSearch/component/functions/cleatInputFields';
import removeItemsFromSessionStorage from 'components/flightSearch/component/functions/removeItemsFromSessionStorage';
import validateFieldsOnSubmit from 'components/flightSearch/component/functions/validateFieldsOnSubmit';
import loadAirfieldDataOnHover from 'components/flightSearch/component/functions/loadAirfieldDataOnHover';
import loadAirfieldDataOnClick from 'components/flightSearch/component/functions/loadAirfieldDataOnClick';
import submitFlightSearch from 'components/flightSearch/component/functions/submitFlightSearch';
import switchAirfields from 'components/flightSearch/component/functions/switchAirfields';

class FlightSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      airfieldsJSON: [],
      resCode: 100,
      isLoading: false,
      loadingFail: false,
      isModalVisible: false,
      timedOut: false,
      isManifestLoaded: false,
      estimatePricesUrl: "",
      flightSearchUrl: "",
      isDomesticFlightAllowed: null,
      shouldSwitchAirfields: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    const checkDomesticFlight = checkIfDomesticFlightIsAllowed(
      props.arriveAirport, 
      props.departAirport, 
      props.departDomesticFlight, 
      props.arriveDomesticFlight
    );
   
    if (checkDomesticFlight === true) return {
      isDomesticFlightAllowed: true
    }
    else if (checkDomesticFlight === false) return {
      isDomesticFlightAllowed: false
    }
  }

  componentDidMount() {
    const { urls } = createProperties(this.props.language);

    this.setState({
      flightSearchUrl: urls.flightSearch,
      isLoading: false
    });
    this.props.checkIfFlightSearchFormIsCompleted(false);
    cleatInputFields(this.props);
    removeItemsFromSessionStorage(this.props.airfieldForFlightSearch);

    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    cleatInputFields(this.props);
    removeItemsFromSessionStorage();
    this.props.checkIfFlightSearchFormIsCompleted(false);
    this.setState({
      isLoading: false
    });

    window.removeEventListener('popstate', this.onBackButtonEvent);  
  }

  onBackButtonEvent = (e) => {
    console.log("popstate fs here", e)
    cleatInputFields(this.props);
    this.setState({
      isLoading: false,
      loadingFail: false
    });
    this.props.checkIfFlightSearchFormIsCompleted(false);
    removeItemsFromSessionStorage(this.props.airfieldForFlightSearch);
  }

  handleOnHover = () => {
    loadAirfieldDataOnHover(this.state.isManifestLoaded, this.props.preview, isMobile, this)
  }

  handleOnClick = () => {
    loadAirfieldDataOnClick(this.props.preview, isMobile, this)
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const checkDomesticFlight = checkIfDomesticFlightIsAllowed(
      this.props.arriveAirport, 
      this.props.departAirport, 
      this.props.departDomesticFlight, 
      this.props.arriveDomesticFlight
    );

    if (checkDomesticFlight === true) this.setState({ isDomesticFlightAllowed: true })
    else if (checkDomesticFlight === false) this.setState({ isDomesticFlightAllowed: false })

    validateFieldsOnSubmit(this.props)

    if (this.props.formType !== "return") {
      if (
        this.props.isDepartAirportValid === true &&
        this.props.isArriveAirportValid === true &&
        this.props.passengers > 0 && this.props.passengers < 1000 &&
        this.props.arriveAirport !== this.props.departAirport &&
        this.props.isTimeValid === true &&
        checkDomesticFlight !== false
        ) {
          this.props.checkIfFlightSearchFormIsCompleted(true);
          this.setState({ isLoading: true, loadingFail: false })
  
          submitFlightSearch(this.props, this.props.location, this.props.language, this, this.props.formType)
        }
    } else {
      if (
        this.props.isDepartAirportValid === true &&
        this.props.isArriveAirportValid === true &&
        this.props.passengers > 0 && this.props.passengers < 1000 &&
        this.props.arriveAirport !== this.props.departAirport &&
        this.props.isTimeValid === true &&
        this.props.isReturnFlightTimeValid === true &&
        checkDomesticFlight !== false
        ) {
          this.props.checkIfFlightSearchFormIsCompleted(true);
          this.setState({ isLoading: true, loadingFail: false })
  
          submitFlightSearch(this.props, this.props.location, this.props.language, this, this.props.formType)
        }
    }
  }
  
  render() {  
    const {
      getFlightTime,
      timeZoneDepart,
      getPassengerNumber,
      validateFlightTime,
      onDepartSelect,
      onArriveSelect,
      airfieldForFlightSearch,
      errorMessages,
      phoneNumber,
      airfieldLocationData,
      isTimeValid,
      arriveAirport,
      departAirport,
      flightSearchData,
      isHomePage,
      isWidget,
      hasMultipleWidgets,
      switchFlightSearchForm,
      formType,
      setReturnFlightTime,
      validateReturnFlightTime,
      setReturnFlightTimeZone,
      setReturnFlightCalendarDisabledDate,
      returnDisabledDate,
      returnFlightTime,
      isReturnFlightTimeValid,
    } = this.props

    return (
      <React.Fragment>
        <StyledForm
          className="form-flightSearch"
          id="form"
          onSubmit={this.handleSubmit}
          onMouseEnter={this.handleOnHover}
          onClick={this.handleOnClick}
          isMobile={isMobile}
          isHomePage={isHomePage}
          isWidget={isWidget}
          hasOtherWidgetBelow={hasMultipleWidgets}
        >
          <FormNavigation 
            oneWayTitle={flightSearchData.one_way_title}
            returnTitle={flightSearchData.return_title}
            multiLegTitle={flightSearchData.multi_leg_title}
            flightSearchPageLink={this.state.flightSearchUrl}
            isWidget={isWidget}
            switchFlightSearchForm={switchFlightSearchForm}
          />
        
          <div 
            id="first_wrapping_div" 
            className="flight_search_first_wrapping_div"
            style={{width: '100%'}}
          >
            <MainForm
              {...this.props}
              airfieldsJSON={this.state.airfieldsJSON}
              onDepartSelect={onDepartSelect}
              onArriveSelect={onArriveSelect}
              airfieldForFlightSearch={airfieldForFlightSearch}
              airfieldLocationData={airfieldLocationData}
              errorMessages={errorMessages}
              isMobile={isMobile}
              isWidget={isWidget}
              switchAirfields={() => switchAirfields(this.props, this)}
            />
            <DateAndTimeForm
              timeZoneDepart={timeZoneDepart}
              getFlightTime={getFlightTime}
              validateFlightTime={validateFlightTime}
              isTimeValid={isTimeValid}
              errorMessage={errorMessages.flight_search_date_of_departure_too_early}
              noDateSelectedErrorMessage={errorMessages.no_date_selected}
              returnJourneyErrorMessage={errorMessages.return_journey_too_early}
              arriveAirport={arriveAirport}
              departAirport={departAirport}
              label={flightSearchData.date_and_time_placeholder}
              timePlaceholder={flightSearchData.time_placeholder}
              datePlaceholder={flightSearchData.date_placeholder}
              isWidget={isWidget}
              flightTime={this.props.flightTime}
              formType="one-way"
              setReturnFlightCalendarDisabledDate={setReturnFlightCalendarDisabledDate}
              returnFlightTime={returnFlightTime}
              areSwitched={this.props.areSwitched}
            />
            {
              formType === "return" && (
                <DateAndTimeForm
                  timeZoneDepart={timeZoneDepart}
                  getFlightTime={getFlightTime}
                  validateFlightTime={validateFlightTime}
                  isTimeValid={isReturnFlightTimeValid}
                  errorMessage={errorMessages.flight_search_date_of_departure_too_early}
                  noDateSelectedErrorMessage={errorMessages.no_date_selected}
                  returnJourneyErrorMessage={errorMessages.return_journey_too_early}
                  arriveAirport={arriveAirport}
                  departAirport={departAirport}
                  label={flightSearchData.return_date_and_time_placeholder}
                  timePlaceholder={flightSearchData.time_placeholder}
                  datePlaceholder={flightSearchData.date_placeholder}
                  isWidget={isWidget}
                  flightTime={this.props.flightTime}
                  returnFlightTime={returnFlightTime}
                  formType="return"
                  setReturnFlightTime={setReturnFlightTime}
                  validateReturnFlightTime={validateReturnFlightTime}
                  setReturnFlightTimeZone={setReturnFlightTimeZone}
                  returnDisabledDate={returnDisabledDate}
                  areSwitched={this.props.areSwitched}
                />
              )
            }
            <PassengersCount 
              getPassengerNumber={getPassengerNumber}
              errorMessages={errorMessages}
              isMobile={isMobile}
              label={flightSearchData.passengers_label}
              isWidget={isWidget}
            />
          </div>
          <div 
            id="second_wrapping_div" 
            className="second_search_first_wrapping_div"
            style={{width: '100%'}}
          >
            <ButtonWrapper
              handleSubmit={this.handleSubmit}
              isLoading={this.state.isLoading}
              language={this.props.language}
              buttonText={flightSearchData.button_text}
              isWidget={isWidget}
            />
          </div>
          {this.state.loadingFail === true ? ( 
            <ErrorMessage 
              errorText={errorMessages.flight_search_failed_request.replace(/{{telephone}}/g, phoneNumber)} 
              isWidget={isWidget}
              isBottomError={true}
            /> 
          ): null}
          {this.state.isDomesticFlightAllowed === false ? ( 
            <ErrorMessage 
              errorText={errorMessages.domestic_journey_not_allowed} 
              isWidget={isWidget}
              isBottomError={true}
            /> 
          ): null}
          
        </StyledForm>
      </React.Fragment>
    )
  }
}

export default FlightSearch

FlightSearch.propTypes = {
  airfieldForFlightSearch: PropTypes.string,
  airfieldLocationData: PropTypes.object,
  areSwitched: PropTypes.bool,
  arriveAirport: PropTypes.string,
  arriveDomesticFlight: PropTypes.number,
  checkIfFlightSearchFormIsCompleted: PropTypes.func,
  departAirport: PropTypes.string,
  departDomesticFlight: PropTypes.number,
  errorMessages: PropTypes.object,
  flightSearchData: PropTypes.object,
  flightTime: PropTypes.string,
  form: PropTypes.object,
  formType: PropTypes.string,
  getArriveAirport: PropTypes.func,
  getArriveAirportCoords: PropTypes.func,
  getDepartAirport: PropTypes.func,
  getDepartAirportCoords: PropTypes.func,
  getFlightTime: PropTypes.func,
  getPassengerNumber: PropTypes.func,
  getTimeZoneArrive: PropTypes.func,
  getTimeZoneDepart: PropTypes.func,
  hasMultipleWidgets: PropTypes.bool,
  isArriveAirportValid: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isDepartAirportValid: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isJourneyValid: PropTypes.bool,
  isReturnFlightTimeValid: PropTypes.bool,
  isTimeValid: PropTypes.bool,
  isWidget: PropTypes.bool,
  language: PropTypes.string,
  location: PropTypes.object,
  mobile: PropTypes.func,
  onArriveSelect: PropTypes.func,
  onDepartSelect: PropTypes.func,
  passengers: PropTypes.number,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  returnDisabledDate: PropTypes.string,
  returnFlightTime: PropTypes.string,
  setArriveDomesticFlightFlag: PropTypes.func,
  setDepartDomesticFlightFlag: PropTypes.bool,
  setReturnFlightCalendarDisabledDate: PropTypes.func,
  setReturnFlightTime: PropTypes.func,
  setReturnFlightTimeZone: PropTypes.func,
  switchFlightSearchForm: PropTypes.func,
  timeZoneArrive: PropTypes.string,
  timeZoneDepart: PropTypes.string,
  validateArriveAirport: PropTypes.func,
  validateDepartAirport: PropTypes.func,
  validateFlightTime: PropTypes.func,
  validateReturnFlightTime: PropTypes.func,
}