/**
 * Main function which switches arrival and departure airfields
 * @author Andrew Bedziak
 * 
 * @param {props} object 
 * @param {self} object 

 * @returns {undefined} 
 */
 
 export default function switchAirfields(props, self) {
    props.switchDepartureAndArrivalAirfields(true)
    
    self.setState(prevState => ( { shouldSwitchAirfields: !prevState.shouldSwitchAirfields } ), () => {
        props.switchDepartureAndArrivalAirfields(self.state.shouldSwitchAirfields)
    });
 }