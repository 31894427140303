/**
 * This function sets and validates time and date of return flight.
 * @author Andrew Bedziak
 * 
 * @param {props} object 
 * @param {state} object 
 * 
 * @returns {object} 
 */

 import { DateTime } from 'luxon';

export default function setAndValidateReturnFlightTime(props, state) {
  const local = DateTime.local();

  const { time, date, returnDate, returnTime } = state;
  const { 
    timeZoneDepart, 
    setReturnFlightTime,
    validateReturnFlightTime,
    arriveAirport,
    departAirport
  } = props;

  if (
    arriveAirport === "" || 
    departAirport === ""
  ) {
    if (
      returnDate !== "" && 
      returnTime !== "" 
    ) {

      const formattedDate = `${returnDate}T${returnTime}`

      setReturnFlightTime(formattedDate);

      return state.isInputValid = true
    }
  }

  if (
    timeZoneDepart !== "" && 
    returnDate !== null && 
    returnDate !== "" && 
    returnTime !== null && 
    returnTime !== ""
  ) {
    const zone = DateTime.fromISO(`${date}T${time}`, { zone: timeZoneDepart });
    const flightZoneTime = zone.ts;

    if (flightZoneTime < local.ts) {

      if (
        arriveAirport && 
        departAirport && 
        arriveAirport !== "" && 
        departAirport !== ""
      ) {

        return state.isInputValid = false
      }
    } else {
      const formattedDate = `${returnDate}T${returnTime}`

      validateReturnFlightTime(true);
      setReturnFlightTime(formattedDate);

      return state.isInputValid = true
    }
  }

  return state;
}