import styled, { css } from 'styled-components'

export const StyledPassengerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  @media(min-width: 1024px) {
    width: unset;
    display: block;
  }

  ${props =>
    props.isWidget &&
      css`
        @media(min-width: 1200px) {
          width: 100%;
          display: flex;
        }
      `
  }
`

export const StyledPassengerButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #DBE9F9;
  background: #DBE9F9;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: none;

  .fs-minus-icon {
    &:after {
      content: "";
      width: 12px;
      height: 2px;
      background-color: #4990E2;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
  }

  .fs-plus-icon {
    &:after {
      content: "";
      width: 12px;
      height: 2px;
      background-color: #4990E2;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) rotate(90deg);
    }

    &:before {
      width: 12px;
      height: 2px;
      background-color: #4990E2;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
  }
`

export const StyledPassengerInput = styled.input`
  width: 45px;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 5px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0 24px;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`

export const StyledPassengerInputTitle = styled.label`
  width: 100%;
  margin-bottom: 0; 
  font-weight: 300; 
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: rgba(0,0,0,.65);

  margin-bottom: 0 !important;
  width: auto !important;

  @media(min-width: 1024px) {
    margin-bottom: 10px !important;
  }

  @media(min-width: 1200px) {
    margin-bottom: ${props => props.isWidget ? "0" : "10px !important"};
  }
`

export const StyledPassengerInnerWrapper = styled.div`
  display: flex; 
  width: auto;
  justify-content: flex-end;
  align-items: center;
`