import React from 'react';
import PropTypes from 'prop-types';

import { StyledErrorMessage } from './styled';

const ErrorMessage = ({ errorText, isCustomError, passengerInputError, mb, dateError, isWidget, isBottomError }) => {
  return (
    <StyledErrorMessage 
      className="flightsearch-errorMessage"
      isCustomError={isCustomError}
      passengerInputError={passengerInputError}
      marginBottom={mb}
      dateError={dateError}
      isWidget={isWidget}
      isBottomError={isBottomError}
    >
      <i className='warning-icon' />
      <span>{errorText}</span> 
    </StyledErrorMessage>
  )
};

ErrorMessage.propTypes = {
  dateError: PropTypes.bool,
  errorText: PropTypes.string,
  isBottomError: PropTypes.bool,
  isCustomError: PropTypes.bool,
  isWidget: PropTypes.bool,
  mb: PropTypes.string,
  passengerInputError: PropTypes.bool,
};

export default ErrorMessage;

