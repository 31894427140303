import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import ErrorMessage from '../Errors/ErrorMessage';

import { StyledFlightSearchAnchor } from '../styled';
import { 
  StyledAutoComplete, 
  StyledAirfieldsDropdown, 
  StyledFormItem,
  StyledInputWrapper
} from './styled';

//function imports

import sort from './functions/sort';
import createAirfieldObject from './functions/createAirfieldObject';
import filterJoinedArrays from './functions/filterJoinedArrays';
import validateAirfield from './functions/validateAirfield';
import validateInputs from './functions/validateInputs';
import handleOnSelect from './functions/handleOnSelect';
import handleAirfieldSwitch from 'components/flightSearch/component/AutoCompleteInput/functions/handleAirfieldSwitch';
import addKeyboardToList from 'components/flightSearch/component/AutoCompleteInput/functions/addKeyboardToList';

import MapIcon from '../svgs/mapIcon'

class AutoCompleteInput extends Component {
  constructor(props) {
    super(props);

    this.listElement = React.createRef()
    this.airfieldList = React.createRef()
    this.counterRef = React.createRef()

    this.state = {
      autoCompleteResult: [],
      isJourneyValid: null,
      isAirfieldValid: null,
      newAirportSelect: null,
      depart: null,
      newAirportDetails: null,
      inputValue: "",
      showDropdown: false,
      counter: 0
    }

    this.windowGlobal = typeof window !== 'undefined' && window;

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleToggleAirportLink = this.handleToggleAirportLink.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.preLoadMap = this.preLoadMap.bind(this);
    this.checkIsAirfieldValid = this.checkIsAirfieldValid.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { newAirportSelect, depart } = props;

    if (newAirportSelect !== state.newAirportSelect) {
      return { newAirportSelect: newAirportSelect, depart: depart }
    }

    if (props.airfieldInputDestination === "from") {
      if (props.isDepartAirportValid === false) {
        return { isAirfieldValid: false }
      } 
      if (props.isDepartAirportValid === null) {
        return { isAirfieldValid: null }
      }
      if (props.isDepartAirportValid === true) {
        return { isAirfieldValid: true }
      }
    }

    if (props.airfieldInputDestination === "to") {
      if (props.isArriveAirportValid === false) {
        return { isAirfieldValid: false }
      }
      if (props.isArriveAirportValid === null) {
        return { isAirfieldValid: null }
      }
      if (props.isArriveAirportValid === true) {
        return { isAirfieldValid: true }
      }
    }

    //validate autocomplete fields to display different errors
    validateInputs(props, state);

    return { state, props };
  }

  componentDidMount() {
    if (this.props.airfieldInputDestination === "from") {
      this.setState({ depart: true})
    }
    
    if (this.windowGlobal) {
      this.windowGlobal.addEventListener('mousedown', this.handleClickOutside);
      this.windowGlobal.addEventListener('keydown', this.handleKeyDown);
      this.windowGlobal.addEventListener('popstate', this.onBackButtonEvent);
    }

    this.setState({ isAirfieldValid: null })
    
    if (this.props.airfieldForFlightSearch) {
      if (this.props.airfieldInputDestination === "from") {
        sessionStorage.setItem("departureAirport", this.props.airfieldForFlightSearch ? this.props.airfieldForFlightSearch : "")
          this.setState({
            isAirfieldValid: true,
            inputValue: this.props.airfieldForFlightSearch ? this.props.airfieldForFlightSearch : "",
            showDropdown: false
          })
      }
      if (this.props.airfieldLocationData) {
        let airfieldCoords = {
          latitude: this.props.airfieldLocationData.latitude,
          longitude: this.props.airfieldLocationData.longitude
        }
        
        { this.props.getTimeZoneDepart && this.props.getTimeZoneDepart(this.props.airfieldLocationData.time_zone) }
        { this.props.getDepartAirportCoords && this.props.getDepartAirportCoords(airfieldCoords) }
        { this.props.validateDepartAirport && this.props.validateDepartAirport(true) };
      }
  
      {
        this.props.getDepartAirport
        ? this.props.getDepartAirport(this.props.airfieldForFlightSearch ? this.props.airfieldForFlightSearch : " ")
        : this.props.getArriveAirport("")
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.newAirportSelect !== null && prevProps.newAirportSelect !== this.state.newAirportSelect){
        let airportSelected = this.props.airfieldsJSON.filter(el => el.id === this.props.newAirportSelect.id)
        let el = airportSelected[0];
        let airfieldDetails = {
          id: el.id,
          airfield_name: `${el.airfield_name} (${el.country_name}) ${el.icao_code}/${el.iata_code}`,
          airfield_time_zone: el.time_zone,
          coords: {
            latitude: el.latitude,
            longitude: el.longitude
          },
          country_name: el.country_name,
          iata_code: el.iata_code,
          icao_code: el.icao_code,
          countryDomesticExcludeFlag: el.countryDomesticExcludeFlag,
          countryFsExcludeFlag: el.countryFsExcludeFlag,
        };

        this.setState({
          newAirportDetails: airfieldDetails
        }, function(){ this.handleSelect() })
    }

    handleAirfieldSwitch(this, prevProps)
  }

  componentWillUnmount() {
    if (this.windowGlobal) {
      this.windowGlobal.removeEventListener('mousedown', this.handleClickOutside);
      this.windowGlobal.removeEventListener('keydown', this.handleKeyDown);
      this.windowGlobal.removeEventListener('popstate', this.onBackButtonEvent);  
    }
  }

  onBackButtonEvent = () => {
    this.setState({
      autoCompleteResult: [],
      isJourneyValid: null,
      isAirfieldValid: null,
      newAirportSelect: null,
      depart: null,
      newAirportDetails: null,
      inputValue: "",
      showDropdown: false,
      counter: 0
    })

    {
      this.props.getTimeZoneDepart 
      ? this.props.getTimeZoneDepart("") 
      : this.props.getTimeZoneArrive("")
    }
    {
      this.props.getDepartAirport
      ? this.props.getDepartAirport("")
      : this.props.getArriveAirport("")
    }
    {
      this.props.getDepartAirportCoords
      ? this.props.getDepartAirportCoords({})
      : this.props.getArriveAirportCoords({})
    }
    {
      this.props.validateDepartAirport
      ? this.props.validateDepartAirport(null)
      : this.props.validateArriveAirport(null);
    }
  
    {
      this.props.setDepartDomesticFlightFlag
      ? this.props.setDepartDomesticFlightFlag({})
      : this.props.setArriveDomesticFlightFlag({})
    }
  }

  handleSelect(e, airfield) {
    const { newAirportDetails, depart } = this.state;

    this.setState({
      isAirfieldValid: true
    })

    if (depart) {
      if (this.props.airfieldInputDestination === "from") {
        if (newAirportDetails) {
          this.setState({
            isAirfieldValid: true,
            inputValue: newAirportDetails.airfield_name,
            showDropdown: false
          })
    
          handleOnSelect(newAirportDetails, this.props) 
        }
      }
    } else {
      if (this.props.airfieldInputDestination === "to") {
        if (newAirportDetails) {

          this.setState({
            isAirfieldValid: true,
            inputValue: newAirportDetails.airfield_name,
            showDropdown: false
          })
    
          handleOnSelect(newAirportDetails, this.props) 
        }
      }
    }
    
    if (e && airfield) {
      this.setState({
        isAirfieldValid: true,
        inputValue: e.target.dataset.value,
        showDropdown: false
      })

      handleOnSelect(airfield, this.props)
    }
  }

  //done
  handleSearch(e) { 
    let value = e.target.value

    this.setState({ 
      inputValue: value,
    });

    let filteredResult;
    let filteredResult2;

    if (value === '') {
      {
        this.props.getDepartAirport
        ? this.props.getDepartAirport("")
        : this.props.getArriveAirport("")
      }
      {
        this.props.validateDepartAirport
        ? this.props.validateDepartAirport(null)
        : this.props.validateArriveAirport(null);
      }
      this.setState({ autoCompleteResult: [], isAirfieldValid: null })
    } 
    
    if (value.length >= 3) {
      //create airfield object from fetched array
      let airfieldListToString = createAirfieldObject(this.props.airfieldsJSON);

      //sort airfields by first three letters
      let byFirstLetter = sort.byFirstLetter(airfieldListToString, filteredResult, value);

      //sort airfields if they value matches any three letter in a string
      let byAnyLetter = sort.byAnyLetter(airfieldListToString, filteredResult2, value);

      //join two sorted arrays
      let joinedArrays = byFirstLetter.slice(0, 10).concat(byAnyLetter.slice(0, 10));

      //filter joined arrays for any duplicate values
      let fullArray = filterJoinedArrays(joinedArrays);

      this.setState({ 
        autoCompleteResult: fullArray.slice(0, 10), 
        showDropdown: true
      });
    }
  }

  //validates input field onBlur trigger
  checkIsAirfieldValid(e) {
    let airfieldCheck = validateAirfield(this.props.airfieldsJSON, this.state.inputValue);

    if (airfieldCheck === false) {
      {
        this.props.validateDepartAirport
        ? this.props.validateDepartAirport(false)
        : this.props.validateArriveAirport(false);
      }
      this.setState({ isAirfieldValid: false })
    };

    if (this.props.airfieldInputDestination === 'from') {
      if (this.state.inputValue !== this.props.departAirport) {
        this.props.validateDepartAirport(false);
        this.setState({ isAirfieldValid: false });
      }

      if (this.state.inputValue === '') {
        this.props.validateDepartAirport(null)
        this.setState({ isAirfieldValid: null })
      }
    }

    if (this.props.airfieldInputDestination === 'to') {
      if (this.state.inputValue !== this.props.arriveAirport) {
        this.props.validateArriveAirport(false)
        this.setState({ isAirfieldValid: false, });
      }

      if (this.state.inputValue === '') {
        this.props.validateArriveAirport(null)
        this.setState({ isAirfieldValid: null })
      }
    }
  }

  //done
  handleToggleAirportLink() {
    if (this.props.airfieldInputDestination === 'from') {
      this.props.openModal(true);
      this.setState({ depart: true });
    }

    if (this.props.airfieldInputDestination === 'to') {
      this.props.openModal(false)
      this.setState({ depart: false })
    }
  }

  preLoadMap() {
    this.props.preLoadFlightSearchMap(true);
  }

  clearInput() {
    if (this.props.airfieldInputDestination === 'from'){
      this.props.getDepartAirport("");

      this.setState({
        inputValue: "",
        showDropdown: false
      })
    } else {
      this.props.getArriveAirport("");

      this.setState({
        inputValue: "",
        showDropdown: false
      })
    }
  }

  handleClickOutside = (e) => {
    if (!e.target.id) {
      this.setState({
        showDropdown: false
      })
    }   
  }

  handleKeyDown (e) {
    addKeyboardToList(e, this)
  }

  render() {
    let children;

    const {  
      autoCompleteResult, 
      isJourneyValid, 
      isAirfieldValid
    } = this.state;

    const { 
      airfieldInputDestination,
      placeHolderValue, 
      validationErrorMessage,
      errorMessages,
      label,
      findNearestText,
      isWidget
    } = this.props;

    if (autoCompleteResult.length > 0) {
      children = autoCompleteResult.map(el => {
        return(
          <li
            id={`option-autocomplete-${airfieldInputDestination}-flightsearch`}
            key={el.airfield_name}
            onMouseDown={(e) => this.handleSelect(e, el)}
            tabindex="0"
            data-value={el.airfield_name}
            ref={this.listElement}
            
          >
            {el.airfield_name}
          </li>
        ) 
      });
    } 

    return (
      <React.Fragment>
        <StyledFormItem isWidget={isWidget} ref={this.counterRef}
          counter={this.state.counter}
        >
          <label>{label}</label>
          <StyledInputWrapper>
            <MapIcon />
            <StyledAutoComplete
              type="text" 
              onChange={(e) => this.handleSearch(e)}
              onFocus={this.clearInput}
              onBlur={(e) => this.checkIsAirfieldValid(e)}
              value={this.state.inputValue}
              className={`autocomplete-${airfieldInputDestination}-airfield-name`}
              placeholder={placeHolderValue}
              aria-label={placeHolderValue}
              airfieldInputDestination={airfieldInputDestination}
              mobile={this.props.isMobile}
              isWidget={isWidget}
              autoComplete="off"
            />
          </StyledInputWrapper>
          <StyledAirfieldsDropdown 
            showDropdown={this.state.showDropdown}
            mobile={this.props.isMobile}   
            ref={dropdown => this.dropdown = dropdown}
            tabindex="0"
            className={`airfield-dropdown-${airfieldInputDestination}`}
            airfieldInputDestination={airfieldInputDestination}
          >
            {children}
          </StyledAirfieldsDropdown>
          <StyledFlightSearchAnchor
            id={`map-popup-${airfieldInputDestination}-flight-search-button`}
            marginTop='0.75em'
            marginBottom='14px'
            fontSize='0.9em'
            fontWeight='300'
            fontColor='#4990e2'
            onClick={this.handleToggleAirportLink}
            onMouseOver={this.preLoadMap}
            destination={airfieldInputDestination}
            isWidget={isWidget}
          >
            {findNearestText}
          </StyledFlightSearchAnchor>
          {isAirfieldValid === false
            ? <ErrorMessage 
                isCustomError 
                errorText={validationErrorMessage}
                mb="1em"
              /> 
            : null 
          }
          {isJourneyValid === false
            ? <ErrorMessage 
                isCustomError 
                errorText={errorMessages.flight_search_same_airports_in_both_fields}
              /> 
            : null 
          }
        </StyledFormItem>
      </React.Fragment>
    )
  }
};

AutoCompleteInput.propTypes = {
  airfieldForFlightSearch: PropTypes.string,
  airfieldInputDestination: PropTypes.string,
  airfieldLocationData: PropTypes.object,  
  airfieldsJSON: PropTypes.array,
  arriveAirport: PropTypes.string,
  departAirport: PropTypes.string,
  errorMessages: PropTypes.object,
  findNearestText: PropTypes.string,
  getArriveAirport: PropTypes.func,
  getArriveAirportCoords: PropTypes.func,
  getDepartAirport: PropTypes.func,
  getDepartAirportCoords: PropTypes.func,
  getTimeZoneArrive: PropTypes.func,
  getTimeZoneArrive: PropTypes.func,
  getTimeZoneDepart: PropTypes.func,
  isMobile: PropTypes.bool,
  isWidget: PropTypes.bool,
  label: PropTypes.string,
  newAirportSelect: PropTypes.object,
  openModal: PropTypes.func,
  placeHolderValue: PropTypes.string,
  preLoadFlightSearchMap: PropTypes.func,
  setArriveDomesticFlightFlag: PropTypes.func,
  setDepartDomesticFlightFlag: PropTypes.func,
  validateArriveAirport: PropTypes.func,
  validateDepartAirport: PropTypes.func,
  validationErrorMessage: PropTypes.string
}

const mapStateToProps = state => ({
  areSwitched: state.flightSearchReducer.areSwitched,
  isArriveAirportValid: state.flightSearchReducer.isArriveAirportValid,
  isDepartAirportValid: state.flightSearchReducer.isDepartAirportValid,
})

export default connect(
  mapStateToProps,
  null
)(AutoCompleteInput);