import React from 'react'
import PropTypes from 'prop-types'

import ErrorMessage from 'components/flightSearch/component/Errors/ErrorMessage';

const isFR = process.env.LANG_CODE === "fr-fr";

function ErrorsWrapper({ 
  isInputValid, 
  date, 
  time, 
  isTimeValid, 
  errorMessage,
  departureBeforeReturnError,
  formType,
  noDateSelectedErrorMessage,
  returnJourneyErrorMessage
}) {

  if ( isInputValid === false && (date !== "" || time !== "") && departureBeforeReturnError !== true) {
    return (
      <ErrorMessage
        errorText={errorMessage}
        isCustomError={true}
        dateError={true}
      />
    )
  } else if (isTimeValid === false && (date === "" || time === "") && departureBeforeReturnError !== true) {
    return (
      <ErrorMessage
        errorText={noDateSelectedErrorMessage}
        isCustomError={true}
        dateError={true}
      />
    )
  } else if (departureBeforeReturnError === true && formType !== "return") {
    return (
      <ErrorMessage
        errorText={returnJourneyErrorMessage}
        isCustomError={true}
        dateError={true}
      />
    )
  } else return null
}

ErrorsWrapper.propTypes = {
  date: PropTypes.string,
  departureBeforeReturnError: PropTypes.bool,
  errorMessage: PropTypes.object,
  formType: PropTypes.string,
  isInputValid: PropTypes.bool,
  isTimeValid: PropTypes.bool,
  noDateSelectedErrorMessage: PropTypes.string,
  returnJourneyErrorMessage: PropTypes.string,
  time: PropTypes.string,
}

export default ErrorsWrapper
