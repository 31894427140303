import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import FlightSearch from 'components/flightSearch/component'

const LoadableFindNearestAirportModal = loadable(() => import('components/flightSearch/FindNearestAirportModal'))

import { useFlightSearchFormDataV2 } from 'components/flightSearch/container/query';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

import {
  getFlightTime,
  getTimeZoneDepart,
  getTimeZoneArrive,
  getDepartAirport,
  getArriveAirport,
  getArriveAirportCoords,
  getDepartAirportCoords,
  getPassengerNumber,
  validateFlightTime,
  validateDepartAirport,
  validateArriveAirport,
  checkIfFlightSearchFormIsCompleted,
  setDepartDomesticFlightFlag,
  setArriveDomesticFlightFlag,
  switchFlightSearchForm,
  setReturnFlightTime,
  validateReturnFlightTime,
  setReturnFlightTimeZone,
  setReturnFlightCalendarDisabledDate,
  switchDepartureAndArrivalAirfields
} from 'state/actions/flightSearch'

import {
  onOpenModal,
  onDepartSelect,
  onArriveSelect,
  newAirportSelect,
  onCloseModal,
  reset,
} from 'state/actions/findNearestAirport';

import { preLoadFlightSearchMap } from 'state/actions/flightSearchMap';

const HomepageFlightSearch = (props) => {
  const { 
    airfieldLocationData, 
    language,
    errorMessages,
    sideBarProps,
    isHomePage,
    isWidget,
    fsExcludeFlag,
    hasMultipleWidgets,
    location
  } = props;

  useEffect(() => {
    props.onCloseModal()

    return () => props.onCloseModal()
  }, [])

  const { node: {data: { map_icon } }} = sideBarProps;

  const data = useFlightSearchFormDataV2();
  
  const fsData = filterPrismicDataByLanguage(data.allPrismicFlightSearchV2.edges, language);

  if (!fsExcludeFlag) {
    return (
      <React.Fragment>
        {
        fsData && (
          <FlightSearch
            {...props}
            airfieldLocationData={airfieldLocationData ? airfieldLocationData : null}
            preview={false}
            errorMessages={errorMessages.node.data}
            language={language}
            flightSearchData={fsData.node.data}
            hasMultipleWidgets={hasMultipleWidgets}
          />
        )
      }
        
        {props.openModal && (
          <LoadableFindNearestAirportModal 
            props={props}
            getAirfield={newAirportSelect} 
            language={language}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  areSwitched: state.flightSearchReducer.areSwitched,
  flightTime: state.flightTimeReducer.flightTime,
  timeZoneDepart: state.flightTimeReducer.timeZoneDepart,
  timeZoneArrive: state.flightTimeReducer.timeZoneArrive,
  arriveAirport: state.flightSearchReducer.arriveAirport,
  departAirport: state.flightSearchReducer.departAirport,
  arriveCoords: state.flightSearchReducer.arriveCoords,
  departCoords: state.flightSearchReducer.departCoords,
  passengers: state.flightSearchReducer.passengers,
  isTimeValid: state.flightTimeReducer.isTimeValid,
  isReturnFlightTimeValid: state.flightTimeReducer.isReturnFlightTimeValid,
  isCompleted: state.flightSearchReducer.isCompleted,
  isDepartAirportValid: state.flightSearchReducer.isDepartAirportValid,
  isArriveAirportValid: state.flightSearchReducer.isArriveAirportValid,
  isPreloaded: state.flightSearchMapReducer.isPreloaded,
  openModal: state.findNearestAirportReducer.openModal,
  airportSelected: state.findNearestAirportReducer.airportSelected,
  depart: state.findNearestAirportReducer.depart,
  isArriveSelected: state.findNearestAirportReducer.isArriveSelected,
  isDepartSelected: state.findNearestAirportReducer.isDepartSelected,
  onPlaceChanged: state.findNearestAirportReducer.onPlaceChanged,
  phoneNumber: state.phoneNumberReducer.phoneNumber,
  departDomesticFlight: state.flightSearchReducer.departDomesticFlight,
  arriveDomesticFlight: state.flightSearchReducer.arriveDomesticFlight,
  formType: state.flightSearchReducer.formType,
  returnDisabledDate: state.flightTimeReducer.returnDisabledDate,
  returnFlightTime: state.flightTimeReducer.returnFlightTime,
  returnFlightTimeZone: state.flightTimeReducer.returnFlightTimeZone,
})

const mapDispatchToProps = dispatch => ({
  checkIfFlightSearchFormIsCompleted: isCompleted => dispatch(checkIfFlightSearchFormIsCompleted(isCompleted)),
  getFlightTime: flightTime => dispatch(getFlightTime(flightTime)),
  getTimeZoneDepart: timeZoneDepart => dispatch(getTimeZoneDepart(timeZoneDepart)),
  getTimeZoneArrive: timeZoneArrive => dispatch(getTimeZoneArrive(timeZoneArrive)),
  getPassengerNumber: passengers => dispatch(getPassengerNumber(passengers)),
  getDepartAirport: departAirport => dispatch(getDepartAirport(departAirport)),
  getArriveAirport: arriveAirport => dispatch(getArriveAirport(arriveAirport)),
  getArriveAirportCoords: coords => dispatch(getArriveAirportCoords(coords)),
  getDepartAirportCoords: coords => dispatch(getDepartAirportCoords(coords)),
  preLoadFlightSearchMap: isPreloaded => dispatch(preLoadFlightSearchMap(isPreloaded)),
  validateFlightTime: isTimeValid => dispatch(validateFlightTime(isTimeValid)),
  validateDepartAirport: isDepartAirportValid => dispatch(validateDepartAirport(isDepartAirportValid)),
  validateArriveAirport: isArriveAirportValid => dispatch(validateArriveAirport(isArriveAirportValid)),
  onCloseModal: () => dispatch(onCloseModal()),
  onOpenModal: depart => dispatch(onOpenModal(depart)),
  onDepartSelect: depart => dispatch(onDepartSelect(depart)),
  onArriveSelect: arrive => dispatch(onArriveSelect(arrive)),
  newAirportSelect: airport => dispatch(newAirportSelect(airport)),
  reset: () => dispatch(reset()),
  setArriveDomesticFlightFlag: (departDomesticFlight) => dispatch(setArriveDomesticFlightFlag(departDomesticFlight)),
  setDepartDomesticFlightFlag: (arriveDomesticFlight) => dispatch(setDepartDomesticFlightFlag(arriveDomesticFlight)),
  switchFlightSearchForm: (formType) => dispatch(switchFlightSearchForm(formType)),
  setReturnFlightTime: (returnFlightTime) => dispatch(setReturnFlightTime(returnFlightTime)),
  validateReturnFlightTime: (isReturnFlightTimeValid) => dispatch(validateReturnFlightTime(isReturnFlightTimeValid)),
  setReturnFlightTimeZone: (returnFlightTimeZone) => dispatch(setReturnFlightTimeZone(returnFlightTimeZone)),
  setReturnFlightCalendarDisabledDate: (returnFlightTimeZone) => dispatch(setReturnFlightCalendarDisabledDate(returnFlightTimeZone)),
  switchDepartureAndArrivalAirfields: (areSwitched) => dispatch(switchDepartureAndArrivalAirfields(areSwitched)),
})

HomepageFlightSearch.propTypes = {
  airfieldId: PropTypes.string,
  airfieldLocationData: PropTypes.object,
  arriveAirport: PropTypes.string,
  arriveCoords: PropTypes.object,
  checkIfFlightSearchFormIsCompleted: PropTypes.func,
  countryName: PropTypes.string,
  departAirport: PropTypes.string,
  departCoords: PropTypes.object,
  errorMessages: PropTypes.object,
  flightTime: PropTypes.string,
  fsExcludeFlag: PropTypes.number,
  getArriveAirport: PropTypes.func,
  getArriveAirportCoords: PropTypes.func,
  getDepartAirport: PropTypes.func,
  getDepartAirportCoords: PropTypes.func,
  getFlightTime: PropTypes.func,
  getPassengerNumber: PropTypes.func,
  getTimeZoneArrive: PropTypes.func,
  getTimeZoneDepart: PropTypes.func,
  hasMultipleWidgets: PropTypes.bool,
  isArriveAirportValid: PropTypes.bool,
  isDepartAirportValid: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isTimeValid: PropTypes.bool,
  isWidget: PropTypes.bool,
  language: PropTypes.string,
  location: PropTypes.object,
  onCloseModal: PropTypes.func,
  openModal: PropTypes.bool,
  pageTitle: PropTypes.string,
  passengers: PropTypes.number,
  phoneNumber: PropTypes.string,
  preLoadFlightSearchMap: PropTypes.func,
  showAddress: PropTypes.bool,
  sideBarProps: PropTypes.object,
  switchDepartureAndArrivalAirfields: PropTypes.func,
  timeZoneArrive: PropTypes.string,
  timeZoneDepart: PropTypes.string,
  validateAutocompleteInputs: PropTypes.func,
  validateFlightTime: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomepageFlightSearch)