import styled, { css } from 'styled-components';

export const StyledAirfieldsDropdown = styled.ul`
  position: absolute;
  display: ${props => (props.showDropdown ? 'block' : 'none')};  
  width: 100%;
  padding-left: 0;
  list-style: none;
  max-height: none !important;
  box-shadow: 0 2px 8px rgba(0,0,0, 0.15);
  background: white;
  z-index: 1000;
  margin-top: 0;

  li {
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: rgba(0,0,0,.65);
    cursor: pointer;
    outline: none;
    font-weight: 600;
    text-align: left;

    &.focused {
      background-color: #ddd;
    }

    &:hover {
      background-color: #ddd;
    }
  }
`

export const StyledAutoComplete = styled.input`
  width: 100%;
  padding: 0;
  padding-left: 11px;
  padding-right: 35px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  outline: #1890ff;
  box-sizing: border-box;
  
  input {
    box-sizing: border-box;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`

export const StyledFormItem = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 1024px) {
    width: calc(40% - 20px);
    margin-right: 20px;
    flex-grow: 1;
    margin-bottom: 0;
  }

  ${props => 
    props.isWidget &&
      css`
        @media (min-width: 1200px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      `
  }




`

export const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;

  .flight-search-autocomplete-svg {
    position: absolute;
    right: 20px;
    top: 10px
  }
`