import styled from 'styled-components';

export const StyledSwitchIconWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: -20px;

  #double-arrows-icon-internal-wrapper {
    transform: rotate(90deg);
    cursor: pointer;
  }

  @media(min-width: 768px) and (max-width: 1200px) {
    display: none;
  }
`