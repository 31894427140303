export default (airfield, props) => {
  {
    props.getTimeZoneDepart 
    ? props.getTimeZoneDepart(airfield.airfield_time_zone) 
    : props.getTimeZoneArrive(airfield.airfield_time_zone)
  }
  {
    props.getDepartAirport
    ? props.getDepartAirport(airfield.airfield_name)
    : props.getArriveAirport(airfield.airfield_name)
  }
  {
    props.getDepartAirportCoords
    ? props.getDepartAirportCoords(airfield.coords)
    : props.getArriveAirportCoords(airfield.coords)
  }
  {
    props.validateDepartAirport
    ? props.validateDepartAirport(true)
    : props.validateArriveAirport(true);
  }
  {
    props.getDepartAirport
    ? sessionStorage.setItem("departureAirport", airfield.airfield_name)
    : sessionStorage.setItem("arrivalAirport", airfield.airfield_name)
  }

  {
    props.setDepartDomesticFlightFlag
    ? props.setDepartDomesticFlightFlag({
      flag: airfield.countryDomesticExcludeFlag,
      countryName: airfield.country_name
    })
    : props.setArriveDomesticFlightFlag({
      flag: airfield.countryDomesticExcludeFlag,
      countryName: airfield.country_name
    })
  }
}