
/**
 * This function creates vslidates date and time of two legs: departure and return
 * @author Andrew Bedziak
 * 
 * @param {self}  
 * @param {prevProps} object 
 * 
 * @returns {string} 
 */

 import { DateTime } from 'luxon';

 export default function validateDepartureAndReturnLegsTimes(self, prevProps) {
    if (self.props.flightTime && self.props.returnFlightTime) {
      const departureDate = DateTime.fromISO(self.props.flightTime)
      const returnDate = DateTime.fromISO(self.props.returnFlightTime);

      if (self.props.flightTime !== prevProps.flightTime) {
        if (departureDate.ts > returnDate.ts) {
          self.props.validateFlightTime(false);
            self.setState({ 
              departureBeforeReturnError: true,
              isInputValid: false
            })
        } else {
          self.setState({ 
            departureBeforeReturnError: false,
            isInputValid: true
          })
        }
      }
    }
 }