import React from 'react';

const CalendarIcon = () => {
  return (
    <div className="flight-search-calendar-svg">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24px" height="22px"
        viewBox="0 0 24 22" version="1.1"
      >
        <title>A2C3CC8C-818C-41CD-88F5-8BCEE2AAB983</title>
        <g id="Icons" stroke="none"
          stroke-width="1" fill="none"
          fill-rule="evenodd"
        >
          <g id="Iconography" transform="translate(-387.000000, -209.000000)"
            fill="#000000"
          >
            <g id="Calendar" transform="translate(387.000000, 209.000400)">
              <path fill="#4990E2" d="M6,0 L6,2 L18,2 L18,0 L20,0 L20,2 L24,2 L24,16.413 L18.414,22 L0,22 L0,2 L4,2 L4,0 L6,0 Z M4,4 L2,4 L2,20 L17,20 L17,14.999 L22,14.999 L22,4 L20,4 L20,5.999 L18,5.999 L18,4 L6,4 L6,5.999 L4,5.999 L4,4 Z M20.586,16.999 L19,16.999 L19,18.586 L20.586,16.999 Z M11,11.9996 L11,13.9996 L4,13.9996 L4,11.9996 L11,11.9996 Z M20,7.9996 L20,9.9996 L4,9.9996 L4,7.9996 L20,7.9996 Z"
                id="Fill"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default CalendarIcon