/**
 * This function creates disabled date for date picker calendar 
 * @author Andrew Bedziak
 * 
 * @returns {string} 
 */

import { DateTime } from 'luxon';

export default function createDisabledDate() {
  const disabledDate = DateTime
    .local()
    .minus({days: 1})
    .endOf('day')
    .toISODate()

    return disabledDate
}