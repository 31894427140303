/**
 * This function sets state to manage opening and closing time picker component.
 * @author Andrew Bedziak
 * 
 * @param {showTimeDropdown} object 
 * @param {self}  
 * 
 * @returns {undefined} 
 */

export default function openTimePickerDropdown(showTimeDropdown, self) {
  self.setState({
    showTimeDropdown: !showTimeDropdown
  }, () => {
    const list = document.querySelector("#time-picker-list");

    if (time === "11:00") {
      list.scrollTop = 800;      
    }
  })
}