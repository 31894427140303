import styled, { css } from 'styled-components';
import DayPicker from 'react-day-picker';

export const StyledDateTimeFormWrapper = styled.div`
  position: relative;
  display: flex; 
  justify-content: space-between; 
  flex-wrap: wrap; 
  flex-grow: 1; 
  align-content: flex-start;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 20px;
    width: calc(50% - 20px);
  }

  ${props => 
    props.isWidget &&
      css`
        @media (min-width: 1200px) {
          margin-bottom: 20px;
          margin-right: 0;
          width: 100%;
        }
      `
  }

  .flight-search-calendar-svg {
    position: absolute;
    right: 20px;
    top: 10px;
    pointer-events: none;
  }
`

export const StyledTimePickerSelect = styled.select`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.timeInputValue ? "#000" : "#636463" };
  padding-left: 10px;
  box-sizing: border-box;
  outline: none;
  caret-color: transparent;
  cursor: pointer;
  height: 45px;
  border-radius: 0;
  border: 2px solid #D4D6DE;
  transition: none;
  appearance: none;
  background: white;
  text-transform: capitalize;

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    cursor: pointer;
  } 

`

export const StyledDatePickerInput = styled.input`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding-left: 10px;
  box-sizing: border-box;
  outline: none;
  caret-color: transparent;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
`

export const StyledDatePicker = styled(DayPicker)`
  display: block !important;
  width: 100%;
  background: white;
  z-index: 10002;
  outline: none;
  border: 2px solid #D4D6DE;

  position: fixed;
  left: 0;
  bottom: 0;
  top: unset;
  min-height: 350px;
  z-index: 20000;

  @media (min-width: 768px) {
    min-height: unset;
    width: 47.5%;
    position: absolute;
    left: unset;
    top: 64px;
    bottom: unset;
  }

  @media screen and (min-device-width:320px) and (max-device-width: 900px) and (orientation:landscape) {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
  }

  @media (min-width: 1024px) {
    width: 100%;
    width: unset;
  }

  .DayPicker-NavBar {
    position: relative
  }

  .DayPicker-wrapper {
    min-width: 280px;
    padding-bottom: 0;
    outline: none
  }

  .DayPicker-NavButton {
    color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .DayPicker-Weekdays {
    display: block;
  }

  .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: white;
  }

  .DayPicker-NavButton--prev, .DayPicker-NavButton--next {
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    top: 25px;
    background-image: none;
    border-radius: 50%;
    border: 1px solid #DBE9F9;
    background: #DBE9F9;
    cursor: pointer;
    outline: none;  
  }

  .DayPicker-NavButton--next::after {
    content: "";
    position: absolute;
    border: solid #4990E2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    top: 45%;
    right: 15%;
    width: 6px;
    height: 6px;
    transform: translate(-50%,-50%) rotate(0deg);
  }

  .DayPicker-NavButton--prev::after {
    content: "";
    position: absolute;
    border: solid #4990E2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    top: 50%;
    right: 15%;
    width: 6px;
    height: 6px;
    transform: translate(-50%,-50%) rotate(180deg);
  }

  .DayPicker-NavButton--prev {    
    left: 40px;
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
  }

  .DayPicker-NavButton--next {    
    left: calc(100% - 40px);
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
  }

  .DayPicker-Body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 10px;
  }

  .DayPicker-Month {
    display: block;
    width: 100%;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 0;
    color: black;

    @media screen and (min-device-width:320px) and (max-device-width: 900px) and (orientation:landscape) {
      margin-right: 100px;
      margin-left: 100px;
    }
  }

  .DayPicker-Day {
    margin-bottom: 10px;
    background: #EFEFEF;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    line-height: 30px;
    color: #000;

    &:hover {
      background-color: #d3d3d3 !important;
    }
  }

  .DayPicker-Week {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .DayPicker-Weekday {
    vertical-align: middle;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 0;
    color: #636463;
  }

  .DayPicker-Caption {
    display: block;
    height: 50px;
    vertical-align: middle;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    line-height: 50px;
    z-index: 1001;
    text-transform: uppercase;

    > div {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      pointer-events: none;
    }
  }

  .DayPicker-Day--disabled {
    background: #f5f5f5;
    border-radius: 0;
    color: #bcbcbc;
    cursor: not-allowed;
  }

  .DayPicker-Day--today {
    color: black;
    background-color: #DBE9F9;
  }

  .DayPicker-Footer {
    display: flex;
    justify-content: center;
    height: 38px;
    padding: 0;
    background: #fff;
    border: 1px solid #fff;

    button {
      color: black;
      font-weight: 700;
    }
  }

  .DayPicker-Day--outside {
    visibility: hidden;
  }
`

export const StyledTimeSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  background: white;
`

export const StyledSelect = styled.input`
  position: relative;
  height: 3.3em;
  width: 100%;
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 0;
  border: 1px solid #b4b5b4;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  background: transparent;
  cursor: pointer;
  z-index: 1;
  /* color: #3C3C3B; */

  @media (min-width: 1024px) {
    font-size: 14px;
  }
  

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

  }
`

export const StyledTimeDropdown = styled.ul`
  position: absolute;
  display: ${props => (props.showTimeDropdown ? 'block' : 'none')};  
  width: calc(100% + 2px) ;
  padding-left: 0;
  list-style: none;
  max-height: 250px;
  box-shadow: 0 2px 8px rgba(0,0,0, 0.15);
  background: white;
  z-index: 1000;
  margin-top: 0;
  overflow-y: scroll;

  li {
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    height: 3em;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 7.5px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,.65);
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #d3d3d3;
    }
  }

  .active {
    background-color: #4990E2;
    font-weight: bold;
  }
`