import React, { Component } from 'react';
import PropTypes from 'prop-types'

import ErrorMessage from '../Errors/ErrorMessage';

import { 
  StyledPassengerButton, 
  StyledPassengerInput, 
  StyledPassengerWrapper,
  StyledPassengerInputTitle,
  StyledPassengerInnerWrapper
} from './styled';

class PassengersCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 1,
      showError: false
    }

    this.incrementValue = this.incrementValue.bind(this);
    this.decrementValue = this.decrementValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  //when component mounts send redux action with initial value = 1
  componentDidMount() {
    this.props.getPassengerNumber(this.state.value)
  }

  //increment passengers value with plus button
  incrementValue = () => {  
    let clickCount = 0;

    if (this.state.value > 31) {
      clickCount++;
      this.setState({value: 32});
      this.props.getPassengerNumber(32)
      sessionStorage.setItem("passengerNumber", 32)

      if (clickCount === 1) this.setState({showError: true})
    }

    if (this.state.value <= 31) {
      this.setState(prevState => ({value: prevState.value + 1, showError: false}));
      this.props.getPassengerNumber(this.state.value + 1)
      sessionStorage.setItem("passengerNumber", this.state.value + 1)
    } else {
      return false;
    }
  }

  //decrement passengers value with minus button
  decrementValue = () => {
    if (this.state.value >= 2) {
      this.setState(prevState => ({value: prevState.value - 1, showError: false}));
      this.props.getPassengerNumber(this.state.value - 1)
      sessionStorage.setItem("passengerNumber", this.state.value - 1)
    } else {
      return false;
    }
  }
  
  handleChange = (e) => {
    const value = e.target.value;
    
    //regular expression to match digits
    var reg = new RegExp('^[0-9]+$');

    if (value > 31) {
      this.setState({value: 32});
      this.props.getPassengerNumber(32);
      sessionStorage.setItem("passengerNumber", 32)
      return false;
    }

    if (value > 32) {
      this.setState({showError: true})
    }

    if (!value.match(reg)) {
      return false
    }

    if (value > 0 && value <= 31) {
      this.setState(prevState => ({
        showError: false,
        value: prevState.value
      }));
      this.props.getPassengerNumber(Number(value))
      sessionStorage.setItem("passengerNumber", Number(value))
    }

    sessionStorage.setItem("passengerNumber", Number(value))
    this.setState({value: Number(value)});
  }

  // on keydown function which listens for backspace key to empty input 
  handleOnKeyDown(e) {
    const value = e.target.value;

    if (
      e.which === 37 ||
      e.which === 39 ||
      e.which === 40 ||
      e.which >= 48 &&
      e.which <= 57 
    ){
      if (value >= 32) {
        this.setState({ value: 32 }, () => this.props.getPassengerNumber(32));
      } else if (Number(value) === 0) {
        this.setState({value: null, showError: true}, () => {
          this.props.getPassengerNumber(0)
        });
      }
      
      else if (value > 1 && value < 32) {
        this.setState({ value: Number(value), showError: false }, () => this.props.getPassengerNumber(this.state.value));
      }
    } else if (e.which === 8) {
      if (value >= 1 && value <= 9) {
        this.setState({value: null, showError: true}, () => {
          this.props.getPassengerNumber(0)
        });
      }
    } 

    if (e.which === 38) {
      this.setState(prevState => {
        if (prevState.value === 32) {
          return {
            showError: true
          }
        }
      })
      if (value < 33) {
        this.setState({ value: Number(value)}, () => this.props.getPassengerNumber(this.state.value));
      }
    }
  }

  handleOnKeyUp = (e) => {
    if (e.which === 38) {
      this.setState(prevState => {
        if (prevState.value === 32) {
          return {
            showError: true
          }
        }
      })
    }
  }

  render() {
    const { label, errorMessages, isMobile, isWidget } = this.props;

    return(
      <StyledPassengerWrapper isWidget={isWidget}>
        <StyledPassengerInputTitle isWidget={isWidget}>
          {label}
        </StyledPassengerInputTitle>
        <StyledPassengerInnerWrapper>
          <StyledPassengerButton 
            id="button-passenger-count_minus"
            onClick={this.decrementValue}
            type="button"
          >
            <span className='fs-minus-icon' />
          </StyledPassengerButton>
          <StyledPassengerInput 
            className="button-passenger-count_input"
            aria-label={label}
            onChange={this.handleChange}
            onKeyDown={(e) => this.handleOnKeyDown(e)}
            value={this.state.value}
            type='number'
            min={1}
            max={32}
            mobile={isMobile}
            autoComplete="off"
          />
          <StyledPassengerButton 
            id="button-passenger-count_plus"
            type="button"
            onClick={this.incrementValue}
          >
            <span className='fs-plus-icon' />
          </StyledPassengerButton>
        </StyledPassengerInnerWrapper>
        {this.state.value !== null && this.state.value !== 0 && this.state.showError === true
          ? <ErrorMessage 
              isCustomError 
              errorText={errorMessages.flight_search_passengers_number_exceeded} 
              passengerInputError={true}
              isWidget={isWidget}
            />
          : null
        }
        {this.state.value === null || this.state.value === 0
          ? <ErrorMessage 
              isCustomError 
              errorText={errorMessages.flight_search_passengers_number_too_low} 
              passengerInputError={true}
              isWidget={isWidget}
            />
          : null
        }
      </StyledPassengerWrapper>
    )
  }
}

export default PassengersCount;

PassengersCount.propTypes = {
  errorMessages: PropTypes.object,
  getPassengerNumber: PropTypes.func,
  isMobile: PropTypes.bool,
  isWidget: PropTypes.bool,
  label: PropTypes.string
}