/**
 * This function loads airfields data on click event only in mobile mode.
 * @author Andrew Bedziak
 * 
 * @param {preview} boolean 
 * @param {isMobile} boolean 
 * @param {self}  

 * @returns {undefined} 
 */

const airfieldsURL = process.env.GATSBY_AIRFIELDS_URL;

export default function loadAirfieldDataOnClick(preview, isMobile, self) {
  if (!preview) {
    if (isMobile) {
      fetch(airfieldsURL)
        .then(res => res.json())
        .then(data => self.setState({ airfieldsJSON: data.airfields }))
        .catch(err => console.log(err))
    }
  }  
}