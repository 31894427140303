export default (joinedArrays) => {
  let newList = joinedArrays.reduce(function(previous, current) {
    var object = previous.filter(object => object.airfield_name === current.airfield_name);
    
    if (object.length == 0) {
      previous.push(current);
    }
    return previous;
  }, []);

  return newList;
}