import React from 'react'
import PropTypes from 'prop-types'

function ListItem({ element, isActive, passTime }) {
  return (
    <li
      key={element}
      value={element}
      isActive={isActive}
      className={`option-timepicker-flightsearch ${isActive ? "active" : ""}`}
      onMouseDown={(e) => passTime(e, e.target.dataset.value)}
      data-value={element}
    >
      {element}
    </li>
  )
}

ListItem.propTypes = {
  element: PropTypes.string,
  isActive: PropTypes.bool,
  passTime: PropTypes.func,
}

export default ListItem
