/**
 * This function removes flight search input fields data from session storage.
 * @author Andrew Bedziak
 * 
 * @param {airfieldForFlightSearch} string 
 * @returns {undefined} 
 */

export default function removeItemsFromSessionStorage(airfieldForFlightSearch) {
  if (!airfieldForFlightSearch) sessionStorage.removeItem('departureAirport');
  sessionStorage.removeItem('arrivalAirport');
  sessionStorage.removeItem('departureDate');
}