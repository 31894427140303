import styled from 'styled-components'

export const StyledErrorMessage = styled.div`
  display: flex;
  justify-content: ${props => props.isBottomError ? "center": "unset"};
  width: 100%;
  color: #46344e;
  padding-top: 10px;
  transition: all 2s;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;

  a {
    margin: 0 auto;
    font-size: 18px;
    width: 100%;
    display: block;
    text-align: center;
  }

  @media(min-width: 1024px) {
    i {
      /* flex: ${props => props.passengerInputError === true ? "20%" : "unset"} ; */
      flex: ${props => {
        if (props.passengerInputError === true && !props.isWidget) {
          return "20%"
        } else {
          return "unset"
        }
      }};
    }
  }

  @media(min-width: 1200px) {
    position: ${props => {
      if (props.passengerInputError === true && !props.isWidget) {
        return "absolute" 
      } else {
        return "relative"
      }
    }};

    i {
      /* flex: ${props => props.passengerInputError === true ? "25%" : "unset"} ; */
      flex: ${props => {
        if (props.passengerInputError === true && !props.isWidget) {
          return "25%"
        } else {
          return "unset"
        }
      }};
    }
  }
`