/**
 * This function creates an array with 24 hour times every 30 minutes
 * @author Andrew Bedziak
 * 
 * @returns {array} 
 */

export default function create24HrTimesList() {
  let fullDay = Array.from(Array(24).keys());
  let desktopTimeArray = [];
  let desktopTime30MinuteArray = [];
  let hour;

  desktopTimeArray = fullDay.map((el, i) => {
    if (el >= 0 && el <= 9) hour = "0" + el + ":00"
    else hour = el.toString() + ":00"
    return hour
  })

  desktopTime30MinuteArray = fullDay.map((el, i) => {
    if (el >= 0 && el <= 9) hour = "0" + el + ":30"
    else hour = el.toString() + ":30"
    return hour
  })

  const timeList = desktopTimeArray.concat(desktopTime30MinuteArray);

  return {
    timeList
  }
}