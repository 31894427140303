/**
 * This function creates form data which is sent to flight search endpoint with http request.
 * @author Andrew Bedziak
 * 
 * @param {props} object 

 * @returns {string} 
 */

import qs from 'qs';
import { DateTime } from 'luxon';

export default function createOneWayFormData(props) {
  const flightTime = `${DateTime.fromISO(`${props.flightTime}`).toFormat("dd-LL-yyyy HH:mm")}`

  let formData = qs.stringify({
    fromICAO: props.departAirport,
    toICAO: props.arriveAirport,
    fromDate: flightTime.split(' ')[0],
    fromTime: flightTime.split(' ')[1],
    typeOfJourney: '0',
    pageTitle: 'QQ',
    fromTimeWgt: '',
    paxWgt: '',
    fromAirfieldName: props.departAirport,
    toAirfieldName: props.arriveAirport,
    datePicker: flightTime.split(' ')[0].replace(/-/g, '/'),
    counter: flightTime.split(' ')[1] + ' hrs',
    numberOfPassenger: props.passengers.toString()
  })

  return formData;
}