export default (airfieldsJSON, value) => {
  const airfieldCheck = airfieldsJSON.some(el => {  
    let airfield = `${el.airfield_name} (${el.country_name}) ${el.icao_code}/${el.iata_code}`;
    
    if (value !== airfield) {
      return false;
    } else {
      return true
    }
  });

  return airfieldCheck;
}