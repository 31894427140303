/**
 * This function creates flight search and estimate prices urls.
 * @author Andrew Bedziak
 * 
 * @param {language} string 

 * @returns {string} 
 */

import createProperties from 'utils/functions/createProperties';

const host = process.env.LOCALE_ORIGIN;
const isBlog = process.env.BUILD_BLOG;
const locale = process.env.LANG_CODE;

export default function createFlightSearchUrls(language) {
  let flightSearchRequestUrl;

  const { urls } = createProperties(language);
        
  if (isBlog) {
    const suffix = locale !== "fr-fr" ? ".html" : "";

    flightSearchRequestUrl = `${host}${urls.estimatePrices}${suffix}`
  } else {
    flightSearchRequestUrl = `${urls.estimatePrices}`
  }

  return flightSearchRequestUrl;
}