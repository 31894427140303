export default (airfieldsJSON) => {
  let airfieldDetails;

  let airfieldList = airfieldsJSON.map(el => {
    if (el.fs_exclude_flag === 0) {
      airfieldDetails = {
        id: el.id,
        airfield_name: `${el.airfield_name} (${el.country_name}) ${el.icao_code}/${el.iata_code}`,
        airfield_time_zone: el.time_zone,
        coords: {
          latitude: el.latitude,
          longitude: el.longitude
        },
        country_name: el.country_name,
        iata_code: el.iata_code,
        icao_code: el.icao_code,
        countryDomesticExcludeFlag: el.countryDomesticExcludeFlag,
        countryFsExcludeFlag: el.countryFsExcludeFlag,
      };
  
      return airfieldDetails
    }

  }).filter(el => el !== undefined);
  
  return airfieldList;
}