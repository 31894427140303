import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/buttons/button';
import LoadingIcon from './LoadingIcon';

import { StyledButtonWrapper } from './styled';

const ButtonWrapper = ({ handleSubmit, isLoading, buttonText }) => {
  return (
    <StyledButtonWrapper>
      <Button 
        className="form-flightsearch-submit-button"
        text={isLoading ? <LoadingIcon buttonText={buttonText} /> : buttonText}
        to={''}
        onClick={handleSubmit} 
        htmlType="submit"
        fontColor='white'
        fontWeight='500'
        fontSize='16px'
        style={{ fontFamily: 'Open Sans, sans-serif', position: 'relative' }}
        disabled={isLoading ? true : false}
      />
    </StyledButtonWrapper> 
  )
}

ButtonWrapper.propTypes = {
  buttonText: PropTypes.string,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ButtonWrapper

