import styled from 'styled-components';

export const StyledFlightSearchNavigation = styled.nav`
  width: 100%;
  border-bottom: 2px solid #D4D6DE;
`

export const StyledNavigationList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 15px;
  justify-content: space-around;
  width: 100%;

  @media(min-width: 768px) {
    justify-content: unset;
    padding: 0;
    padding-left: 30px;
  }

  @media(min-width: 1200px) {
    justify-content: ${props => props.isWidget ? "space-around" : "unset"};
  }

  li {
    position: relative;
    text-transform: uppercase;
    margin-right: 0;
    font-weight: bold;
    font-size: 13px;

    @media(min-width: 1200px) {
      display: ${props => props.isWidget && "flex"};
      justify-content: ${props => props.isWidget && "center"};
      align-items: ${props => props.isWidget && "center"};
    }

    @media(min-width: 768px) {
      margin-right: 40px;
    }

    a {
      color: #000;
      display: block;
      padding: 13px 0;
      cursor: pointer;

      @media(min-width: 1200px) {
        text-align: ${props => props.isWidget && "center"};
      }
    }

    a:hover {
      text-decoration: none;
    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -2px;
      border-bottom: 2px solid transparent;
      width: 100%;
      height: 2px;
    }
    &:hover {
      &::after {
        border-bottom: 2px solid #E30613;
      }
    }
  }

`