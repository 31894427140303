/**
 * This function clears all input fields in flight search form
 * @author Andrew Bedziak
 * 
 * @param {props} object 

 * @returns {undefined} 
 */

export default function cleatInputFields(props) {
  if (!props.airfieldForFlightSearch) { 
    props.getDepartAirport && props.getDepartAirport("") 
    props.getDepartAirportCoords && props.getDepartAirportCoords(null)
    props.getTimeZoneDepart && props.getTimeZoneDepart("")
    props.validateDepartAirport && props.validateDepartAirport(null) 
    props.setDepartDomesticFlightFlag && props.setDepartDomesticFlightFlag(null) 
  }
  
  { props.getArriveAirport && props.getArriveAirport("") }
  { props.getArriveAirportCoords && props.getArriveAirportCoords(null) }

  { props.getTimeZoneArrive && props.getTimeZoneArrive("") }
  
  { props.setArriveDomesticFlightFlag && props.setArriveDomesticFlightFlag(null) }

  { props.validateArriveAirport && props.validateArriveAirport(null) }
  { props.getFlightTime && props.getFlightTime("") }
}