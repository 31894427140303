/**
 * This function listens to keydown event and allows using keyboard in the airfield list. 
 * @author Andrew Bedziak
 * 
 * @returns {e} object -  event object
 * @returns {self} object -  this passed down from component
 */

let counter = 0;

import handleOnSelect from 'components/flightSearch/component/AutoCompleteInput/functions/handleOnSelect';

export default function addKeyboardToList(e, self) {
  const list = document.querySelector(`form ul.airfield-dropdown-${self.props.airfieldInputDestination}`);
  const listItems = list.querySelectorAll("li");
  const array = Array.from(listItems)
    
  if (array.length > 0) {
    if (e.which === 40) {
      counter++
    }

    if (e.which === 38) {
      counter--
    }

    array.map((el, index) => {
      if (e.which === 40) {
        if (index === counter - 1) {
          el.focus({preventScroll: true})
          el.className = "focused"
        } else {
          el.className = ""
        }
        if (counter > array.length) {
          counter = array.length
        }
      }  

      if (e.which === 38) {
        if (index === counter - 1) {
          el.focus({preventScroll: true})
          el.className = "focused"
        } else {
          el.className = ""
        }
        if (counter < 1) {
          counter = 1
        }
      }  

      if (e.which === 13) {
        if (el.className === "focused") {
          if (self.state.depart === true) {
            const findAirfield = self.state.autoCompleteResult.find(airfield => airfield.airfield_name === el.textContent);

            handleOnSelect(findAirfield, self.props) 
            counter = 0
    
            self.setState({
              inputValue: el.textContent, 
              showDropdown: false,
              counter: 0,
              isAirfieldValid: true,
              autoCompleteResult: []
            })
          }

          if (!self.props.depart) {
            const findAirfield = self.state.autoCompleteResult.find(airfield => airfield.airfield_name === el.textContent);

            handleOnSelect(findAirfield, self.props) 
            counter = 0

            self.setState({
              inputValue: el.textContent, 
              showDropdown: false,
              counter: 0,
              isAirfieldValid: true,
              autoCompleteResult: []
            })
          }
        }
      }
    })
  }
}