/**
 * This function sets and validates time and date of departure flight.
 * @author Andrew Bedziak
 * 
 * @param {props} object 
 * @param {state} object 
 * 
 * @returns {object} 
 */

import { DateTime } from 'luxon';

export default function setAndValidateDepartureFlightTime(props, state) {
  const local = DateTime.local();

  const { time, date } = state;
  const { 
    timeZoneDepart, 
    getFlightTime,
    validateFlightTime,
    arriveAirport,
    departAirport
  } = props;

  if (
    arriveAirport === "" || 
    departAirport === ""
  ) {
    if (
      date !== "" && 
      time !== "" && 
      timeZoneDepart === ""
    ) {

      const formattedDate = `${date}T${time}`

      getFlightTime(formattedDate);

      return state.isInputValid = true
    }
  }

  if (
    timeZoneDepart !== "" && 
    date !== null && 
    date !== "" && 
    time !== null && 
    time !== ""
  ) {
    const zone = DateTime.fromISO(`${date}T${time}`, { zone: timeZoneDepart });
    const flightZoneTime = zone.ts;
    const formattedDate = `${date}T${time}`;

    if (flightZoneTime < local.ts) {
      getFlightTime(formattedDate);
      validateFlightTime(false);

      if (
        arriveAirport && 
        departAirport && 
        arriveAirport !== "" && 
        departAirport !== "" 
      ) {

        getFlightTime(formattedDate);
        validateFlightTime(false);

        return state.isInputValid = false

      }
    } else {

      if ( state.departureBeforeReturnError === true) {
        validateFlightTime(false);
        getFlightTime(formattedDate);
      } else {

        validateFlightTime(true);
        getFlightTime(formattedDate);

        return state.isInputValid = true
      }
    }
  }

  return state;
}