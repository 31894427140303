/**
 * This function creates form data which is sent to flight search endpoint with http request.
 * @author Andrew Bedziak
 * 
 * @param {props} object 

 * @returns {string} 
 */

 import qs from 'qs';
 import { DateTime } from 'luxon';

export default function createReturnFormData(props) {
  const { passengers, departAirport, arriveAirport } = props
  const flightTime = `${DateTime.fromISO(`${props.flightTime}`).toFormat("dd-LL-yyyy HH:mm")}`
  const returnFlightTime = `${DateTime.fromISO(`${props.returnFlightTime}`).toFormat("dd-LL-yyyy HH:mm")}`

  let formData = qs.stringify({
    pageTitle: 'EstimatePrices',
    journeyType: '1',

    //pax return and outbound
    numberOfPassenger: [passengers, passengers],

    //outbound airfield 
    fromICAO: [departAirport, arriveAirport],
    toICAO: [arriveAirport, departAirport],

    // outbound date and time
    fromDate: [ flightTime.split(" ")[0], returnFlightTime.split(" ")[0] ],
    fromTime: [ flightTime.split(" ")[1], returnFlightTime.split(" ")[1] ],

    // outbound and return airfield
    fromAirfieldName_return: departAirport,
    toAirfieldName_return: arriveAirport,

    // outbound date and time
    datePicker_return_1: flightTime.split(" ")[0].replace(/-/g, '/'),
    counter1: flightTime.split(" ")[1] + ' hrs',

    //return date and time
    datePicker_return_2: returnFlightTime.split(" ")[0].replace(/-/g, '/'),
    counter2: returnFlightTime.split(" ")[1] + ' hrs',
  
      //pax return and outbound
    }, { indices: false })
 
   return formData;
 }