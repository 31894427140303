/**
 * This function validates flight search form fields on the submit event.
 * @author Andrew Bedziak
 * 
 * @param {props} object 

 * @returns {undefined} 
 */

export default function validateFieldsOnSubmit(props) {
  if (!props.flightTime) {
    props.validateFlightTime(false)
  }

  if (!props.returnFlightTime) {
    props.validateReturnFlightTime(false)
  }

  if (props.isDepartAirportValid !== true) {
    props.validateDepartAirport(false);
    props.checkIfFlightSearchFormIsCompleted(false);

    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  }

  if (props.isArriveAirportValid !== true) {
    props.validateArriveAirport(false);
    props.checkIfFlightSearchFormIsCompleted(false);

    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  }

  if (props.isTimeValid === false) {
    props.checkIfFlightSearchFormIsCompleted(false);

    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  } 

  if (props.isJourneyValid === false) {
    props.checkIfFlightSearchFormIsCompleted(false);

      dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  }

  if (props.passengers === 0 || props.passengers > 1000) {
    props.checkIfFlightSearchFormIsCompleted(false);

    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  }

  if (props.arriveAirport === props.departAirport) {
    props.checkIfFlightSearchFormIsCompleted(false);
    
    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  } 
}