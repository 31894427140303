/**
 * Main function which submits flight search data.
 * @author Andrew Bedziak
 * 
 * @param {props} object 
 * @param {location} object 
 * @param {language} string 
 * @param {self}  
 * @param {formType} string  

 * @returns {undefined} 
 */

import axios from 'axios';

import createOneWayFormData from 'components/flightSearch/component/functions/createOneWayFormData';
import createReturnFormData from 'components/flightSearch/component/functions/createReturnFormData';
import createFlightSearchUrls from 'components/flightSearch/component/functions/createFlightSearchUrls';

export default function submitFlightSearch(props, location, language, self, formType) {
  let formData;

  const flightSearchRequestUrl = createFlightSearchUrls(language);

  if (formType === "return") {
    formData = createReturnFormData(props)
  } else {
    formData = createOneWayFormData(props)
  }

  axios({
    url: flightSearchRequestUrl,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: false,
    timeout: 20000,
    data: formData
  })
  .then(function (response) {
    if (response) {
      const resCode = response.status

      if (resCode === 200) {
        setTimeout(() => {
          self.setState({ isLoading: false })
        }, 10000)

        dataLayer.push({
          "event": "formSubmitted",
          'formSuccess': true
        });

        window.location.assign(response.request.responseURL);
      }

      if (resCode >= 400 && resCode < 600) {
        
        self.setState({ loadingFail: true, isLoading: false })

        dataLayer.push({
          "event": "formSubmitted",
          'formSuccess': false
        });
      }
    } else {
      self.setState({ loadingFail: true, isLoading: false })
      dataLayer.push({
        "event": "formSubmitted",
        'formSuccess': false
      });
    }
  })
  .catch(error => {     
    console.log("error", error)
    console.log("error code", error.code)
    console.log('Error message', error.message);

    if (error.code === "ECONNABORTED") {
      if (error.message.includes("timeout")) {
        console.log("timeout caught")
        self.setState({ loadingFail: true, isLoading: false })

        dataLayer.push({
          "event": "formSubmitted",
          'formSuccess': false
        });
      }
    }

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

    console.log(error.config);      
     
    dataLayer.push({
      "event": "formSubmitted",
      'formSuccess': false
    });
  });
}