exports.byFirstLetter = (airfieldListToString, filteredResult, value) => {
  filteredResult = airfieldListToString
    .filter(el => el.airfield_name.toUpperCase().indexOf(value.toUpperCase()) === 0)
    .sort((a, b) => {
      const first = a.airfield_name.toUpperCase()
      const second = b.airfield_name.toUpperCase();

      return ( first < second) ? -1 : (first > second) ? 1 : 0
    })
    .slice(0, 10)

  return filteredResult;
}

exports.byAnyLetter = (airfieldListToString, filteredResult2, value) => {
  filteredResult2 = airfieldListToString
    .filter(el => el.airfield_name.toUpperCase().indexOf(value.toUpperCase()) !== -1)
    .sort((a, b) => {
      const first = a.airfield_name.toUpperCase()
      const second = b.airfield_name.toUpperCase();
      
      return ( first < second) ? -1 : (first > second) ? 1 : 0
    })
    .slice(0, 10)

  return filteredResult2;
}