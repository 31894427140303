/* eslint react/no-unused-state: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import DatePickerSelect from 'components/flightSearch/component/DateAndTimeForm/DatePickerSelect';
import TimePickerMobile from 'components/flightSearch/component/DateAndTimeForm/TimePickerMobile';
import ErrorsWrapper from 'components/flightSearch/component/DateAndTimeForm/ErrorsWrapper';

import List from 'components/flightSearch/component/DateAndTimeForm/Time/List'

import { 
  StyledDateTimeFormWrapper, 
  StyledSelect, 
  StyledTimeSelectWrapper
} from './styled';

import CalendarIcon from '../svgs/calendarIcon'

import create24HrTimesList from 'components/flightSearch/component/DateAndTimeForm/functions/create24HrTimesList';
import handleClickOutsideComponent from 'components/flightSearch/component/DateAndTimeForm/functions/handleClickOutsideComponent';
import openTimePickerDropdown from 'components/flightSearch/component/DateAndTimeForm/functions/openTimePickerDropdown';
import setAndValidateDepartureFlightTime from 'components/flightSearch/component/DateAndTimeForm/functions/setAndValidateDepartureFlightTime';
import setAndValidateReturnFlightTime from 'components/flightSearch/component/DateAndTimeForm/functions/setAndValidateReturnFlightTime';
import createDisabledDate from 'components/flightSearch/component/DateAndTimeForm/functions/createDisabledDate';
import createReturnFlightDisabledDate from 'components/flightSearch/component/DateAndTimeForm/functions/createReturnFlightDisabledDate';
import validateDepartureAndReturnLegsTimes from 'components/flightSearch/component/DateAndTimeForm/functions/validateDepartureAndReturnLegsTimes';

const { timeList } = create24HrTimesList();

class DateAndTimeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      returnDate: "",
      time: '11:00',
      returnTime: '11:00',
      isInputValid: null,
      showTimeDropdown: false,
      departureBeforeReturnError: false
    }

    this.windowGlobal = typeof window !== 'undefined' && window;
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.formType !== "return") setAndValidateDepartureFlightTime(props, state)
    if (props.formType === "return") setAndValidateReturnFlightTime(props, state)
  }

  componentDidMount() {
    if (this.windowGlobal) {
      this.windowGlobal.addEventListener('mousedown', this.handleClickOutside)
      this.windowGlobal.addEventListener('popstate', this.onBackButtonEvent);  
    }

    if (this.props.formType === "one-way") this.props.validateFlightTime(null)
    else this.props.validateReturnFlightTime(null)
  }

  componentDidUpdate(prevProps, prevState) {
    createReturnFlightDisabledDate(this, prevState)
    validateDepartureAndReturnLegsTimes(this, prevProps)
  }

  componentWillUnmount() {
    if (this.windowGlobal) {
      this.windowGlobal.removeEventListener('mousedown', this.handleClickOutside);
      this.windowGlobal.removeEventListener('popstate', this.onBackButtonEvent);  
    }
  }

  onBackButtonEvent = (e) => {
    console.log("date pop state")

    this.setState({
      date: "",
      returnDate: "",
      time: '11:00',
      returnTime: '11:00',
      isInputValid: null,
      showTimeDropdown: false,
      departureBeforeReturnError: false
    })

    if (this.props.formType === "return") {
      this.props.validateReturnFlightTime(null)
      this.props.setReturnFlightTime("")
    } else {
      this.props.validateFlightTime(null)
      this.props.getFlightTime("")
    }
  }

  passTime = (e, value) => {
    if (this.props.formType === "return") {
      this.setState({ returnTime: value, showTimeDropdown: false })
    } else {
      this.setState({ time: value, showTimeDropdown: false })
    }
    
    sessionStorage.setItem("departureTime", value)
  }

  validateTime = (time) => {
    if (this.props.formType === "return") {
      this.setState({ returnTime: time })
    } else {
      this.setState({ time: time })
    }
  }

  validateDate = (date) => {
    if (this.props.formType === "return") {
      this.setState({ returnDate: date })
    } else {
      this.setState({ date: date })
    }
  }

  handleClickOutside = (e) => {  
    handleClickOutsideComponent(e, this)
  }

  openDropdown = () => {
    openTimePickerDropdown(this.state.showTimeDropdown, this)
  }
  
  render() {
   
    const { 
      errorMessage, 
      timePlaceholder, 
      datePlaceholder, 
      label, 
      isWidget, 
      formType,
      returnDisabledDate,
      noDateSelectedErrorMessage,
      returnJourneyErrorMessage
    } = this.props;

    const disabledDate = createDisabledDate()
    
    return (
      <StyledDateTimeFormWrapper isWidget={isWidget} formType={formType}>
        <label>{label}</label>
        <DatePickerSelect 
          disabledDate={formType === "return" && returnDisabledDate ? returnDisabledDate : disabledDate }
          validateDate={this.validateDate}
          isMobile={isMobile}
          defaultDate={null}
          datePlaceholder={datePlaceholder}
          formType={formType}
        />
        <div style={{ marginBottom: 0, width: '47.5%', position: "relative" }}>
          {isMobile ?
            <StyledTimeSelectWrapper>
              <TimePickerMobile
                className="time-picker-flight-search"
                passTime={this.passTime}
                isMobile={isMobile}
                validateTime={this.validateTime}
                defaultTime={formType === "return" ? this.state.returnTime : this.state.time}
                type="text"
                newArray={timeList}
                timePlaceholder={timePlaceholder}
              />
            </StyledTimeSelectWrapper>
            :
            <StyledTimeSelectWrapper>
              <CalendarIcon />
              <StyledSelect
                className={`time-picker-flight-search ${this.state.showTimeDropdown ? "timeselect-active" : ""}`}
                defaultValue={formType === "return" ? this.state.returnTime : this.state.time}
                value={formType === "return" ? this.state.returnTime : this.state.time}
                onClick={() => this.openDropdown()}
                type="text"
                autocomplete="off"
                placeholder={timePlaceholder}
              />
              <List 
                showTimeDropdown={this.state.showTimeDropdown}
                time={formType === "return" ? this.state.returnTime : this.state.time}
                passTime={this.passTime}
              />
            </StyledTimeSelectWrapper>
          }
        </div>
        <ErrorsWrapper 
          isInputValid={this.state.isInputValid}
          date={formType === "return" ? this.state.returnDate : this.state.date}
          time={formType === "return" ? this.state.returnTime : this.state.time}
          isTimeValid={this.props.isTimeValid}
          errorMessage={errorMessage}
          noDateSelectedErrorMessage={noDateSelectedErrorMessage}
          departureBeforeReturnError={this.state.departureBeforeReturnError}
          returnJourneyErrorMessage={returnJourneyErrorMessage}
          formType={formType}
        />
      </StyledDateTimeFormWrapper>
    )
  }
}

DateAndTimeForm.propTypes = {
  datePlaceholder: PropTypes.string,
  errorMessage: PropTypes.string,
  formType: PropTypes.string,
  getFlightTime: PropTypes.func,
  isTimeValid: PropTypes.bool,
  isWidget: PropTypes.bool,
  label: PropTypes.string,
  noDateSelectedErrorMessage: PropTypes.string,
  returnDisabledDate: PropTypes.string,
  returnJourneyErrorMessage: PropTypes.string,
  setReturnFlightTime: PropTypes.func,
  timePlaceholder: PropTypes.string,
  validateFlightTime: PropTypes.func,
  validateReturnFlightTime: PropTypes.func,
}

export default DateAndTimeForm
