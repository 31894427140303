/**
 * This function sets state for departure and arrival arifields in the inputs and redux store when using switch button.
 * @author Andrew Bedziak
 * 
 * @param {self} object 

 * @returns {undefined} 
 */

export default function handleAirfieldSwitch(self, prevProps) {

	if (self.props.areSwitched !== prevProps.areSwitched) {	
		if (self.props.airfieldInputDestination === "from") {
			self.setState({ inputValue: prevProps.arriveAirport}, () => {
				self.props.getDepartAirport(self.state.inputValue)
				sessionStorage.setItem("departureAirport", self.state.inputValue)

				if (prevProps.arriveAirport) {
					self.props.validateDepartAirport(true)
				} 

				if (prevProps.arriveCoords) {
					self.props.getDepartAirportCoords(prevProps.arriveCoords)
				}

				if (prevProps.timeZoneArrive) {
					self.props.getTimeZoneDepart(prevProps.timeZoneArrive)
				}
			})
		}

		if (self.props.airfieldInputDestination === "to") {
			self.setState({ inputValue: prevProps.departAirport}, () => {
				self.props.getArriveAirport(self.state.inputValue);
				sessionStorage.setItem("arrivalAirport", self.state.inputValue)
		
				if (prevProps.departAirport) {
					self.props.validateArriveAirport(true);
				} 

				if (prevProps.departCoords) {
					self.props.getArriveAirportCoords(prevProps.departCoords)
				}

				if (prevProps.timeZoneDepart) {
					self.props.getTimeZoneArrive(prevProps.timeZoneDepart)
				}
			})
		}
	}

	if (self.props.departAirport !== prevProps.departAirport) {
		if (!self.props.departAirport) {
			if (self.props.airfieldInputDestination === "from") {
				self.props.validateDepartAirport(null);
			}
		}

		if (!self.props.arriveAirport) {
			if (self.props.airfieldInputDestination === "to") {
				self.props.validateArriveAirport(null);
			}
		}
	}
}