import { useStaticQuery, graphql } from "gatsby";

export const useFlightSearchFormDataV2 = () => {
  const data = useStaticQuery(
    graphql`
      query flightSearchFormQueryV2 {
        allPrismicFlightSearchV2 {
          edges {
            node {
              lang
              data {
                arrival_label
                arrival_placeholder
                button_text
                date_and_time_placeholder
                return_date_and_time_placeholder
                date_placeholder
                departure_label
                departure_placeholder
                find_nearest_text
                flight_search_page_link {
                  url
                }
                time_placeholder
                return_title
                passengers_label
                one_way_title
                multi_leg_title
              }
            }
          }
        }
      }
    `
  )

  return data
}