import React from 'react';

const DoubleArrows = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20px" height="24px"
      viewBox="0 0 20 24" version="1.1"
    >
      <title>0F06A932-A90E-4F48-9250-8CAB6264ED55</title>
      <g id="Icons" stroke="none"
        stroke-width="1" fill="#4990E2"
        fill-rule="evenodd"
      >
        <g id="Iconography" transform="translate(-551.000000, -154.000000)"
          fill="#4990E2"
        >
          <g id="Double-Arrow-LR" transform="translate(551.323223, 154.939340)">
            <path d="M20.5606602,7.88388348 L14.5,1.8232233 L8.43933983,7.88388348 L9.85356809,9.29811174 L13.3242233,5.82666017 L13.3232126,20.5606602 L15.3232129,20.5606602 L15.3242233,5.47366017 L19.1463879,9.29815579 L20.5606602,7.88388348 Z M5.32321285,1.56066017 L3.32321261,1.56066017 L3.3242233,16.2926602 L-0.146476441,12.8232531 L-1.56066017,14.2374369 L4.5,20.298097 L10.5606602,14.2374369 L9.14642874,12.8232054 L5.3242233,16.6456602 L5.32321285,1.56066017 Z" id="Fill"
              transform="translate(9.500000, 11.060660) scale(-1, 1) rotate(90.000000) translate(-9.500000, -11.060660) "
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

  export default DoubleArrows;