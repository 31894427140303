import React from 'react'
import PropTypes from 'prop-types'

import ListItem from 'components/flightSearch/component/DateAndTimeForm/Time/ListItem'

import { StyledTimeDropdown } from 'components/flightSearch/component/DateAndTimeForm/styled';

import create24HrTimesList from 'components/flightSearch/component/DateAndTimeForm/functions/create24HrTimesList';

function List({ showTimeDropdown, time, passTime }) {
  const { timeList } = create24HrTimesList();

  return (
    <StyledTimeDropdown 
      showTimeDropdown={showTimeDropdown}
      className='time-picker'
      id="time-picker-list"
      name="counter"
      multiple={true}
    >
      {timeList.sort().map(el => {
        const isActive = time === el

        return (
          <ListItem 
            element={el}
            isActive={isActive}
            passTime={passTime}
            
          />
        )
      })}
    </StyledTimeDropdown>
  )
}

List.propTypes = {
  passTime: PropTypes.func,
  showTimeDropdown: PropTypes.bool,
  time: PropTypes.string,
}

export default List
