import {
  GET_FLIGHT_TIME,
  GET_TIME_ZONE_DEPART,
  GET_TIME_ZONE_ARRIVE,
  VALIDATE_FLIGHT_TIME,
  GET_PASSENGER_NUMBER,
  GET_DEPART_AIRPORT,
  GET_ARRIVE_AIRPORT,
  GET_ARRIVE_AIRPORT_COORDS,
  GET_DEPART_AIRPORT_COORDS,
  VALIDATE_DEPART_AIRPORT,
  VALIDATE_ARRIVE_AIRPORT,
  CHECK_FLIGHT_SEARCH_COMPLETION,
  GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG,
  SET_DEPART_DOMESTIC_FLIGHT_FLAG,
  SET_ARRIVE_DOMESTIC_FLIGHT_FLAG,
  SWITCH_FLIGHT_SEARCH_FORM_TYPE,
  SET_RETURN_FLIGHT_TIME,
  VALIDATE_RETURN_FLIGHT_TIME,
  SET_RETURN_FLIGHT_TIME_ZONE,
  SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE,
  SWITCH_AIRFIELDS
} from './constants';

export const getFlightTime = flightTime => ({
  type: GET_FLIGHT_TIME,
  payload: flightTime
});

export const validateFlightTime = isTimeValid => ({
  type: VALIDATE_FLIGHT_TIME,
  payload: isTimeValid
});

export const getTimeZoneDepart = timeZoneDepart => ({
  type: GET_TIME_ZONE_DEPART,
  payload: timeZoneDepart
});

export const getTimeZoneArrive = timeZoneArrive => ({
  type: GET_TIME_ZONE_ARRIVE,
  payload: timeZoneArrive
});

export const getDepartAirport = departAirport => ({
  type: GET_DEPART_AIRPORT,
  payload: departAirport
});

export const validateDepartAirport = isDepartAirportValid => ({
  type: VALIDATE_DEPART_AIRPORT,
  payload: isDepartAirportValid
});

export const validateArriveAirport = isArriveAirportValid => ({
  type: VALIDATE_ARRIVE_AIRPORT,
  payload: isArriveAirportValid
});

export const getArriveAirport = arriveAirport => ({
  type: GET_ARRIVE_AIRPORT,
  payload: arriveAirport
});

export const getDepartAirportCoords = coords => ({
  type: GET_DEPART_AIRPORT_COORDS,
  payload: coords
});

export const getArriveAirportCoords = coords => ({
  type: GET_ARRIVE_AIRPORT_COORDS,
  payload: coords
});

export const getPassengerNumber = passengers => {
  return {
    type: GET_PASSENGER_NUMBER,
    payload: passengers
  };
};

export const getPassengerMultiLegInitialNumber = paxMultiLeg => {
  return {
    type: GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG,
    payload: paxMultiLeg
  };
};

export const checkIfFlightSearchFormIsCompleted = isCompleted => {
  return {
    type: CHECK_FLIGHT_SEARCH_COMPLETION,
    payload: isCompleted
  };
};

export const setDepartDomesticFlightFlag = departDomesticFlight => {
  return {
    type: SET_DEPART_DOMESTIC_FLIGHT_FLAG,
    payload: departDomesticFlight
  };
};

export const setArriveDomesticFlightFlag = arriveDomesticFlight => {
  return {
    type: SET_ARRIVE_DOMESTIC_FLIGHT_FLAG,
    payload: arriveDomesticFlight
  };
};

export const switchFlightSearchForm = formType => ({
  type: SWITCH_FLIGHT_SEARCH_FORM_TYPE,
  payload: formType
});

// RETURN JOURNEY

export const setReturnFlightTime = returnFlightTime => ({
  type: SET_RETURN_FLIGHT_TIME,
  payload: returnFlightTime
});

export const validateReturnFlightTime = isReturnFlightTimeValid => ({
  type: VALIDATE_RETURN_FLIGHT_TIME,
  payload: isReturnFlightTimeValid
});

export const setReturnFlightTimeZone = returnFlightTimeZone => ({
  type: SET_RETURN_FLIGHT_TIME_ZONE,
  payload: returnFlightTimeZone
});

export const setReturnFlightCalendarDisabledDate = returnDisabledDate => ({
  type: SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE,
  payload: returnDisabledDate
});

export const switchDepartureAndArrivalAirfields = areSwitched => ({
  type: SWITCH_AIRFIELDS,
  payload: areSwitched
});