export default (state, props) => {
  if (state.isAirfieldValid === false) {
    return {
      isJourneyValid: null
    }
  }

  if (props.arriveAirport === '' || props.departAirport === '') {
    return {
      isJourneyValid: null
    } 
  }

  if (props.arriveAirport !== '' && props.departAirport !== '' && props.arriveAirport !== props.departAirport) {
      return {
        isJourneyValid: true
      } 
  } else {
    return {
      isJourneyValid: false
    }
  }
}