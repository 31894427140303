import React from 'react'
import PropTypes from 'prop-types'

import DoubleArrows from 'components/flightSearch/component/svgs/doubleArrows'

import { StyledSwitchIconWrapper } from 'components/flightSearch/component/SwitchButton/styled'

function SwitchButton({ isWidget, switchAirfields }) {
  return (
    <React.Fragment>
      {
        isWidget && 
        (
          <StyledSwitchIconWrapper>
            <div 
              id="double-arrows-icon-internal-wrapper" 
              onClick={switchAirfields} 
            >
              <DoubleArrows />
            </div>
          </StyledSwitchIconWrapper>
        )
      }
    </React.Fragment>
  )
}

SwitchButton.propTypes = {
  isWidget: PropTypes.bool,
  switchAirfields: PropTypes.func
}

export default SwitchButton
