/**
 * This function creates disabled date for return date picker calendar 
 * @author Andrew Bedziak
 * 
 * @param {self}  
 * @param {prevState} object 
 * 
 * @returns {string} 
 */

import { DateTime } from 'luxon';

export default function createReturnFlightDisabledDate(self, prevState) {
  if (self.state.date !== "" && self.state.time !== "") {
    if (self.state.date !== prevState.date) {

      const disabledDate = DateTime
        .fromISO(self.state.date + "T" + self.state.time)
        .endOf('day')
        .toISODate()

      self.props.setReturnFlightCalendarDisabledDate(disabledDate)
    }
  }
}