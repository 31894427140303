/* eslint react/no-unused-state: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CalendarIcon from '../svgs/calendarIcon';

import { StyledTimePickerSelect } from './styled'; 

class TimePickerMobile extends Component {
  state = {
    timeInputValue: this.props.defaultTime
  } 

  render() {
    const { isMobile, newArray, passTime, timePlaceholder, defaultTime } = this.props;

    return (
      <React.Fragment>
        <CalendarIcon />
        <StyledTimePickerSelect
          className="select-btn"
          timeInputValue={defaultTime}
          onChange={(e) => {
            passTime(e, e.target.options[e.target.selectedIndex].text)
            // this.props.validateTime(`${e.target.options[e.target.selectedIndex].text}`)
          }}
          mobile={isMobile}
        >
          {newArray.sort().map((el, index) => {
            if (index === 0) {
              return (
                <option
                  key={el}
                  disabled 
                  selected 
                  hidden
                  style={{textTransform: "capitalize"}}
                >
                  {defaultTime}
                </option>
              )
            }

            return (
              <option
                key={el}
                className={`option-timepicker-foptionghtsearch`}
              >
                {el}
              </option>
            )
          })}
        </StyledTimePickerSelect>
      </React.Fragment>
    );
  }
}

export default TimePickerMobile;

TimePickerMobile.propTypes = {
  defaultTime: PropTypes.string,
  isMobile: PropTypes.bool,
  newArray: PropTypes.array,
  passTime: PropTypes.func,
  timePlaceholder: PropTypes.string,
  // validateTime: PropTypes.func,
}
