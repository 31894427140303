import styled, { keyframes, css } from 'styled-components'

export const StyledForm = styled.form`
  width: 100vw;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: #FFF;
  padding: 0 0 1em 0;
  box-shadow: ${props => props.isHomePage ? "none" : "0 0 12px 0 rgba(72,79,93,0.17)"};
  z-index: 20;

  #fs-heading {
    display: block;
    width: 100%;
    margin-top: 30px !important;
    color: #000000;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: 600;
    color: #636463;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #3C3C3B;
  }

  input {
    border-radius: 0;
    height: 45px;
    border: 2px solid #D4D6DE;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24,144,255,.2);
    }
  }

  input::placeholder {
    text-transform: capitalize;
    color: #636463;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }

  #first_wrapping_div {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      justify-content: space-between;
    }

    @media (min-width: 1024px) {
      padding: 30px;
      flex-wrap: nowrap;
    }

    ${props => 
      props.isWidget &&
        css`
          @media (min-width: 1200px) {
            flex-wrap: wrap;
            width: 100vw;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 0;
          }
        `
    }
  }

  #second_wrapping_div{
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 1024px) {
      padding: 0;
    }
  }

  ${props => 
    props.isWidget &&
      css`
        margin-top: ${props.hasOtherWidgetBelow && "25px"};
        
        @media (min-width: 1200px) {
          position: ${props.hasOtherWidgetBelow ? "static" : "absolute"}; 
          max-width: ${props.hasOtherWidgetBelow ? "100%" : "380px"};
          margin-bottom: ${props.hasOtherWidgetBelow && "25px"};
          top: 200px;
        }
      `
  }

  ${props => 
    !props.isWidget &&
      css`
        @media (min-width: 1200px) {
          min-height: 190px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      `
  }

`

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: unset !important;
  align-items: center;
  padding-top: 1em;
  margin-bottom: 0.5em;

  button {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    margin-bottom: 0.5em;

    @media (min-width: 992px) {
      width: auto;
    }
  }
`

export const StyledFlightSearchAnchor = styled.a`
  display: inline-block;
  width: auto;
  margin-top: 10px;
  float: right;
  color: #4990e2 !important;
  font-weight: 600;
  font-size: 10px;
  font-family: "Open Sans";
  cursor: pointer;
  text-transform: uppercase;

  :hover {
    text-decoration: underline;
    color: #23527c !important;
  }

  @media(min-width: 1024px) {
    width: 100%;
    text-align: left; 
  }

  ${props => 
    props.isWidget &&
      css`
        @media(min-width: 1024px) {
          text-align: right
        }
      `
  }
`

const spin = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`

export const StyledSpinner = styled.div`
  position: absolute; 
  width: auto; 
  top: 9px; 
  right: 10px; 
  border: 2px solid #fff; 
  border-top: 2px solid grey; 
  border-radius: 50%;
  width: 26px;
  height: 26px;
  animation: 1200ms ${spin} linear infinite;
`