import {
    ONOPENMODAL,
    ONDEPARTSELECT,
    ONARRIVESELECT,
    NEWAIRPORTSELECT,
    ONCLOSEMODAL,
    RESET
  } from './constants';
  
  export const onOpenModal = depart => ({
    type: ONOPENMODAL,
    payload: depart
  });
  export const onDepartSelect = depart => ({
    type: ONDEPARTSELECT,
    payload: depart
  });
  export const onArriveSelect = arrive => ({
    type: ONARRIVESELECT,
    payload: arrive
  });
  export const newAirportSelect = airport => ({
    type: NEWAIRPORTSELECT,
    payload: airport
  });
  export const onCloseModal = () => ({
    type: ONCLOSEMODAL,
    payload: false
  });
  export const reset = () => ({
    type: RESET,
    payload: null
  });
