import React from 'react'
import PropTypes from 'prop-types'

import { StyledSpinner } from './styled';

const LoadingIcon = ({ buttonText }) => {
  return (
    <React.Fragment>
      <span>
        {buttonText}
        <StyledSpinner />
      </span>
    </React.Fragment>
  )
}

LoadingIcon.propTypes = {
  buttonText: PropTypes.string
}

export default LoadingIcon
