/**
 * This function handles click events outside date and time picker components.
 * @author Andrew Bedziak
 * 
 * @param {event} object 
 * @param {self}  
 * 
 * @returns {undefined} 
 */

export default function handleClickOutsideComponent(event, self) {
  if ( event.target.className.toString().indexOf("time-picker-flight-search") > -1) {
    return false
  }

  if (
    event.target.className && 
    event.target.className.toString().indexOf("styled__StyledTimeDropdown") < 0
  ) {
    self.setState({
      showTimeDropdown: false,
    })
  } 

  if (
    // event.target.id !== "" || 
    event.target.id === "" || 
    event.target.className === ""
  ){
    self.setState({
      showTimeDropdown: false,
    })
  } 
}