import React from 'react'
import PropTypes from 'prop-types'

import AutoCompleteInput from 'components/flightSearch/component/AutoCompleteInput'
import SwitchButton from 'components/flightSearch/component/SwitchButton'

const MainForm = ({
  getTimeZoneDepart,
  getTimeZoneArrive,
  getDepartAirport,
  getArriveAirport,
  arriveAirport,
  departAirport,
  getArriveAirportCoords,
  getDepartAirportCoords,
  onAirportSelect,
  airportSelected,
  onDepartSelect,
  onArriveSelect,
  onOpenModal,
  depart,
  airfieldsJSON,
  validateDepartAirport,
  validateArriveAirport,
  preLoadFlightSearchMap,
  isMobile,
  airfieldForFlightSearch,
  errorMessages,
  airfieldLocationData,
  isDepartAirportValid,
  isArriveAirportValid,
  setDepartDomesticFlightFlag,
  setArriveDomesticFlightFlag,
  flightSearchData,
  isWidget,
  switchAirfields,
  departCoords,
  arriveCoords,
  timeZoneDepart,
  timeZoneArrive
}) => {
  return (
    <React.Fragment>
      <AutoCompleteInput
        airfieldInputDestination="from"
        validationErrorMessage={errorMessages.flight_search_invalid_departure_airport}
        getTimeZoneDepart={getTimeZoneDepart}
        getDepartAirport={getDepartAirport}
        getDepartAirportCoords={getDepartAirportCoords}
        arriveAirport={arriveAirport}
        departAirport={departAirport}
        airportSelect={onAirportSelect}
        onDepartSelect={onDepartSelect}
        onArriveSelect={onArriveSelect}
        newAirportSelect={airportSelected}
        airfieldsJSON={airfieldsJSON}
        depart={depart}
        openModal={onOpenModal}
        validateDepartAirport={validateDepartAirport}
        preLoadFlightSearchMap={preLoadFlightSearchMap}
        airfieldForFlightSearch={airfieldForFlightSearch}
        airfieldLocationData={airfieldLocationData}
        errorMessages={errorMessages}
        isMobile={isMobile}
        isDepartAirportValid={isDepartAirportValid}
        isArriveAirportValid={isArriveAirportValid}
        setDepartDomesticFlightFlag={setDepartDomesticFlightFlag}
        label={flightSearchData.departure_label}
        placeHolderValue={flightSearchData.departure_placeholder}
        findNearestText={flightSearchData.find_nearest_text}
        isWidget={isWidget}
        departCoords={departCoords}
        arriveCoords={arriveCoords}
        timeZoneArrive={timeZoneArrive}
        timeZoneDepart={timeZoneDepart}
      />
      <SwitchButton 
        isWidget={isWidget}
        switchAirfields={switchAirfields}
      />
      <AutoCompleteInput
        airfieldInputDestination="to"
        validationErrorMessage={errorMessages.flight_search_invalid_arrival_airport}
        getTimeZoneArrive={getTimeZoneArrive}
        getArriveAirport={getArriveAirport}
        getArriveAirportCoords={getArriveAirportCoords}
        arriveAirport={arriveAirport}
        departAirport={departAirport}
        airportSelect={onAirportSelect}
        onDepartSelect={onDepartSelect}
        onArriveSelect={onArriveSelect}
        newAirportSelect={airportSelected}
        airfieldsJSON={airfieldsJSON}
        depart={depart}
        openModal={onOpenModal}
        validateArriveAirport={validateArriveAirport}
        preLoadFlightSearchMap={preLoadFlightSearchMap}
        airfieldForFlightSearch={airfieldForFlightSearch}
        errorMessages={errorMessages}
        isMobile={isMobile}
        isDepartAirportValid={isDepartAirportValid}
        isArriveAirportValid={isArriveAirportValid}
        setArriveDomesticFlightFlag={setArriveDomesticFlightFlag}
        label={flightSearchData.arrival_label}
        placeHolderValue={flightSearchData.arrival_placeholder}
        findNearestText={flightSearchData.find_nearest_text}
        isWidget={isWidget}
        departCoords={departCoords}
        arriveCoords={arriveCoords}
        timeZoneArrive={timeZoneArrive}
        timeZoneDepart={timeZoneDepart}
      />
    </React.Fragment>
  )
}

MainForm.propTypes = {
  airfieldForFlightSearch: PropTypes.string,
  airfieldLocationData: PropTypes.string,
  airfieldsJSON: PropTypes.array,
  airportSelected: PropTypes.object,
  arriveAirport: PropTypes.string,
  arriveCoords: PropTypes.object,
  depart: PropTypes.bool,
  departAirport: PropTypes.string,
  departCoords: PropTypes.object,
  errorMessages: PropTypes.object,
  flightSearchData: PropTypes.object,
  getArriveAirport: PropTypes.func,
  getArriveAirportCoords: PropTypes.func,
  getDepartAirport: PropTypes.func,
  getDepartAirportCoords: PropTypes.func,
  getTimeZoneArrive: PropTypes.func,
  getTimeZoneDepart: PropTypes.func,
  isArriveAirportValid: PropTypes.bool,
  isDepartAirportValid: PropTypes.bool,
  isMobile: PropTypes.bool,
  isWidget: PropTypes.bool,
  onAirportSelect: PropTypes.func,
  onArriveSelect: PropTypes.func,
  onDepartSelect: PropTypes.func,
  onOpenModal: PropTypes.func,
  preLoadFlightSearchMap: PropTypes.func,
  setArriveDomesticFlightFlag: PropTypes.func,
  setDepartDomesticFlightFlag: PropTypes.func,
  switchAirfields: PropTypes.func,
  timeZoneArrive: PropTypes.string,
  timeZoneDepart: PropTypes.string,
  validateArriveAirport: PropTypes.func,
  validateDepartAirport: PropTypes.func
}

export default MainForm
